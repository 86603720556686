import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoimentById } from "../../../api/getAppoiments.api";
import { AdaugaReview, HeaderClient } from "../../../components";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import DetaliiSedinta from "../../../components/Sedinta/DetaliiSedinta";
import DetaliiSedintaClient from "../../../components/Sedinta/DetaliiSedintaClient";
import Notite from "../../../components/Sedinta/Notite";
import NotiteClient from "../../../components/Sedinta/NotiteClient";
import ReviewSedinta from "../../../components/Sedinta/ReviewSedinta";

const SedintaClient = () => {
  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div>
          <div className="my-auto">
            <div className="mt-2 mb-1 lg:m-0 flex justify-end">
              <button className="w-32 justify-center py-4 px-2 border rounded-md shadow-sm text-xs border-primary text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                Inapoi
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-5">
          <div className="col-span-2">
            <div>
              <DetaliiSedintaClient />
            </div>
            <div className="mt-5">
              <NotiteClient />
            </div>
          </div>
          <div className="mt-5 xl:mt-0">
            <AdaugaReview />
          </div>
        </div>
      </div>
    </>
  );
};

export default SedintaClient;
