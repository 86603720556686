import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";
import { auth } from ".";

export const getSpecialist = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getuser`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getSpecialists = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getspecialists`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getSpecialistsHp = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getspecialistshp`,
      {}
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getClients = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getclients`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getCompanies = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getcompanies`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getSpecialistById = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getspecialistbyid/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getClientById = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getclientbyid/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getCompanyById = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getcompanybyid/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};
