import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";

export const getSumaDatorata = async (companyId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/suma-datorata/${companyId}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    console.log(response);
    // window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getCompanyInvoices = async (companyId, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/getcompanyinvoices/${companyId}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    // const response = res.data;
    // console.log(response);
    // window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getTransaction = async (token, year, month) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/gettransactions/${year}/${month}}`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    console.log(response);
    // window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getClientSecret = async (appId, loc) => {
  //   const res = await fetch(`${process.env.REACT_APP_DEV_HOST}/create-payment-intent`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: JSON.stringify({
  //       paymentMethodType: "card",
  //       currency: "eur",
  //     }),
  //   }).then((res) => {
  //     console.log(res.json());
  //   });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/create-payment-intent`,

      {
        appId: appId,
        loc: loc,
        //   price: price,
      },
      {
        headers: {
          // id_token: token,
        },
      }
    );
    const response = res.data;
    window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createInvoice = async (appId, loc, session_id) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/create-invoice`,

      {
        appId: appId,
        loc: loc,
        session_id: session_id,
        //   price: price,
      },
      {
        headers: {
          // id_token: token,
        },
      }
    );
    const response = res.data;
    console.log(res);
    console.log("aici44");
    // window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const companyPayApi = async (token, companyId) => {
  //   const { email, uid, metadata } = user._delegate;
  // var token = "";

  // token = idToken;
  try {
    console.log(token);
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/company-pay`,
      {
        companyId: companyId,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const companyMarkAsPaid = async (token, companyId, session_id) => {
  try {
    console.log(token);
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/stripe/company-mark-as-paid`,
      {
        companyId: companyId,
        session_id: session_id,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    // window.location.href = response.url;
    return res;
  } catch (err) {
    console.log(err);
  }
};
