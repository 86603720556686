import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
  apiKey: "AIzaSyDt9_nSzF9eXtlYR2UwigDy-UhxdWoxglA",

  authDomain: "solution-talk-f2f6c.firebaseapp.com",

  projectId: "solution-talk-f2f6c",

  storageBucket: "solution-talk-f2f6c.appspot.com",

  messagingSenderId: "404952162956",

  appId: "1:404952162956:web:475119b23a07ac91cad1a9",

  measurementId: "G-GTVXRD7PDN",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();

provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const logInWithEmailAndPassword = async ({ email, password }) => {
  console.log(email, password);
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const registerWithEmailAndPassword = async ({ email, password }) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    console.log(user);
  } catch (err) {
    console.error(err);
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export const forgotPasswordWithEmail = async (email) => {
  try {
    const res = await auth.sendPasswordResetEmail(email).then(function () {
      toast.success("Va rugam sa va verificati email-ul!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  } catch (err) {
    console.error(err);
    toast.error(err.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

export default firebase;
