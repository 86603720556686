import React, {
  useEffect,
  useState,
  createRef,
  useRef,
  useContext,
} from "react";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import roLocale from "@fullcalendar/core/locales/ro";
import { sliceEvents, createPlugin } from "@fullcalendar/core";
import { auth } from "../../../api";
import { toast } from "react-toastify";
import {
  getAppoiments,
  getspecialistAvailableSlots,
} from "../../../api/getAppoiments.api";
import Moment from "moment";
import {
  addClientToAppointment,
  createappointment,
} from "../../../api/createAppoinment";
import { UserContext } from "../../../providers/UserProvider";
import { Link, useParams } from "react-router-dom";
import { HeaderClient } from "../../../components";
import { getClientSecret } from "../../../api/stripe.api";

var events = [
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-16 12:45", // a property!
    end: "2022-05-16 13:45", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-17 08:00", // a property!
    end: "2022-05-17 09:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-19 14:00", // a property!
    end: "2022-05-19 15:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Online",
      name: "Daniela Lungu",
    },
  },
  {
    // this object will be "parsed" into an Event Object
    title: "The Title", // a property!
    start: "2022-05-15 13:00", // a property!
    end: "2022-05-15 14:00", // a property! ** see important note below about 'end' **
    extendedProps: {
      location: "Cabinet",
      name: "Daniela Lungu",
    },
  },
];

// var events = [];
const Calendar = () => {
  const [appointments, setAppoiments] = useState([]);
  var calendarRef = createRef();
  var popupRef = createRef();
  var popupDateRef = createRef();
  var popupTimeRef = createRef();
  const [event, setEvent] = useState();
  var popupPrice = createRef();
  var [popupStartDateValue, setPopupStartDateValue] = useState();
  var [popupEndDateValue, setPopupEndDateValue] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  var [showPopup, setShowPopup] = useState(false);
  var [availableOffline, setAvailableOffline] = useState(false);
  var [availableOnline, setAvailableOnline] = useState(false);
  var [selectedLocation, setSelectedLocation] = useState();
  const { user, setUser } = useContext(UserContext);
  const { id } = useParams();
  // const [popupLocation, setPopupLocation] =

  const onChangeCheckbox = (event) => {
    setSelectedLocation(event.target.value);
    // var { name, value } = event.target;
    // if (!event.target.checked) {
    //   value = "off";
    // }
    // setClient((prevState) => ({
    //   ...prevState,
    //   specialistInfo: {
    //     ...prevState.specialistInfo, // shallow copy all previous state
    //     [name]: value, // update specific key/value
    //   },
    // }));
    // console.log(client);
  };

  const paySlot = () => {
    getClientSecret(selectedEventId, selectedLocation);
  };

  const saveSlot = () => {
    console.log(user.user);
    // var app = {
    //   specialist: user.user.uid,
    //   availableSlot: true,
    //   date: popupStartDateValue,
    //   startHour: Moment(popupStartDateValue).format("HH:mm"),
    //   endHour: Moment(popupEndDateValue).format("HH:mm"),
    // };
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        var type;
        var cpId;
        if (user.user.companyId || user.user.isCompany) {
          type = "company";
        } else {
          type = "personal";
        }
        if (user.user.isCompany == true) {
          cpId = user.user.publicId;
        } else {
          cpId = user.user.companyId;
        }
        // var cpId;

        addClientToAppointment(
          selectedLocation,
          user.user.firstName,
          selectedEventId,
          idToken,
          type,
          cpId
        ).then((res) => {
          if (res.status == 200) {
            toast.success("Te-ai programat cu succes!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setShowPopup(false);
            event.remove();
          } else {
            toast.error("A aparut o eroare la efectuarea programarii!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          console.log(res);
        });
      });
  };

  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getspecialistAvailableSlots(idToken, id).then((res) => {
          console.log(res);
          res.appointments.map((e) => {
            var x = {
              // this object will be "parsed" into an Event Object
              title: e.clientFirstName, // a property!
              start: Moment(e.date).format("YYYY-MM-DD") + " " + e.startHour, // a property!
              end: Moment(e.date).format("YYYY-MM-DD") + " " + e.endHour, // a property! ** see important note below about 'end' **

              extendedProps: {
                location: e.location,
                availableOffline: e.availableOffline,
                availableOnline: e.availableOnline,
                name: e.clientFirstName,
                _id: e._id,
                test: e.date,
              },
            };
            // events.push(x);
            // console.log(x);
            var eventData = calendarRef.current.getApi().addEvent(x);
            // console.log(eventData);
            eventData.setProp("backgroundColor", "rgba(248, 154, 118,0.3)");
          });
          // console.log(events);
          // events = [
          //   {
          //     // this object will be "parsed" into an Event Object
          //     title: "The Title", // a property!
          //     start: "2022-05-16 12:45", // a property!
          //     end: "2022-05-16 13:45", // a property! ** see important note below about 'end' **
          //     extendedProps: {
          //       location: "Online",
          //       name: "Daniela Lungu",
          //     },
          //   },
          // ];
          // console.log(events);
          // calendarRef.current.getApi().addEvent(x);
          // calendarRef.current.getApi().changeView("timeGridWeek");
          // console.log(calendarRef.current.getApi());
          // calendarRef.events = events;
        });
      });
  }, []);
  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-5">
          <div className="col-span-1">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="px-4 py-5 sm:px-5 sm:py-5 mini-calendar font-openSans">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  locale={roLocale}
                  height={"auto"}
                  dayHeaderContent={(arg) => {
                    const day = arg.date.getUTCDay();
                    var dayToString = "";
                    switch (day) {
                      case 1:
                        dayToString = "LUN";
                        break;
                      case 2:
                        dayToString = "MAR";
                        break;
                      case 3:
                        dayToString = "MIE";
                        break;
                      case 4:
                        dayToString = "JOI";
                        break;
                      case 5:
                        dayToString = "VIN";
                        break;
                      case 6:
                        dayToString = "SAM";
                        break;
                      case 0:
                        dayToString = "DUM";
                        break;
                    }
                    return (
                      <>
                        <div className="font-openSans font-bold text-xs text-light-blue">
                          <p>{dayToString}</p>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
            {/* <div className="mt-5">
              <div className="mb-3">
                <p className="text-lg font-bold">Filtreaza Istoric</p>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="left basis-1/6 font-bold">
                    <h3 className="text-dark-blue pb-2.5">Denumire Client</h3>
                    <input
                      className="mb-4 max-w-full border rounded-md h-9 text-lg px-3"
                      type="text"
                    />
                    <div>
                      <h3 className="text-dark-blue pb-2.5">Data</h3>
                      <div className="flex items-center gap-1 pb-6">
                        <input
                          className="max-w-100px border rounded-md h-9 text-lg px-3"
                          type="text"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="1"
                          viewBox="0 0 9 1"
                        >
                          <line
                            id="Line_227"
                            data-name="Line 227"
                            x2="9"
                            transform="translate(0 0.5)"
                            fill="none"
                            stroke="#707070"
                            strokeWidth="1"
                          />
                        </svg>
                        <input
                          className="max-w-100px border rounded-md h-9 text-lg px-3"
                          type="text"
                        />
                      </div>
                      <h3 className="text-dark-blue pb-2.5 ">Organizeaza:</h3>
                      <div className="flex flex-col gap-2.5 text-dark-blue pb-8">
                        <div className="flex items-center gap-2.5">
                          <input
                            name="recente"
                            type="checkbox"
                            className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                          />
                          <span>Cele mai recente</span>
                        </div>
                        <div className="flex items-center gap-2.5">
                          <input
                            name="vechi"
                            type="checkbox"
                            className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                          />
                          <span>Cele mai vechi</span>
                        </div>
                      </div>
                      <h3 className="text-dark-blue pb-2.5 ">Tipologie</h3>
                      <div className="flex flex-col gap-2.5 text-dark-blue">
                        <div className="flex items-center gap-2.5">
                          <input
                            name="online"
                            type="checkbox"
                            className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                          />
                          <span>Sedinte Online</span>
                        </div>
                        <div className="flex items-center gap-2.5">
                          <input
                            name="cabinet"
                            type="checkbox"
                            className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                          />
                          <span>Sedinte la Cabinet</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="xl:col-span-4">
            <div
              className={
                (!showPopup ? "hidden " : "") +
                "w-80 h-96 fixed inset-0 m-auto z-50"
              }
              ref={popupRef}
            >
              <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
                <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
                  <p className="text-white font-openSans font-bold">
                    Programare
                  </p>
                  <p
                    onClick={(e) => {
                      setSelectedLocation("");
                      setShowPopup(false);
                    }}
                    className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
                  >
                    x
                  </p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupDateRef}
                  ></p>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupTimeRef}
                  ></p>
                  <div
                    className={
                      (availableOnline ? "" : "hidden ") +
                      "relative flex items-start font-openSans text-base text-dark-blue font-bold"
                    }
                  >
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotOnline"
                        aria-describedby="availableSlotOnline-description"
                        name="location"
                        type="radio"
                        value="online"
                        // checked={
                        //   client.specialistInfo
                        //     ? client.specialistInfo
                        //         .specializationTypePsihoterapeut == "on"
                        //       ? true
                        //       : false
                        //     : ""
                        // }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>

                    <div className="ml-1">
                      <label
                        htmlFor="specializationTypePsihoterapeut"
                        className=" "
                      >
                        Online
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      (availableOffline ? "" : "hidden ") +
                      "relative flex items-start font-openSans text-base text-dark-blue font-bold"
                    }
                  >
                    <div className="flex items-center h-5">
                      <input
                        onChange={onChangeCheckbox}
                        id="availableSlotCabinet"
                        aria-describedby="specializationTypePsihiatru-description"
                        name="location"
                        value="offline"
                        type="radio"
                        // checked={
                        //   client.specialistInfo
                        //     ? client.specialistInfo
                        //         .specializationTypePsihiatru == "on"
                        //       ? true
                        //       : false
                        //     : ""
                        // }
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-1">
                      <label htmlFor="availableSlotCabinet" className=" ">
                        Cabinet
                      </label>
                    </div>
                  </div>
                  <p
                    className="font-openSans text-xl text-dark-blue font-bold"
                    ref={popupPrice}
                  ></p>
                  {!user.user.companyId && user.user.isCompany != true ? (
                    <>
                      <h1>{user.user.isCompany == true}</h1>
                      <button
                        // to={"/stripe/" + selectedEventId + "/" + selectedLocation}
                        className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                        onClick={paySlot}
                        type="button"
                      >
                        {" "}
                        Plateste
                      </button>
                    </>
                  ) : (
                    <button
                      // to={"/stripe/" + selectedEventId + "/" + selectedLocation}
                      className="w-2/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                      onClick={saveSlot}
                      type="button"
                    >
                      {" "}
                      Salveaza
                    </button>
                  )}
                </div>
              </div>
            </div>
            <FullCalendar
              plugins={[timeGridPlugin, interactionPlugin]}
              locale={roLocale}
              initialView="timeGridWeek"
              selectable={true}
              select={(data) => {
                popupDateRef.current.innerHTML =
                  "Data: " + Moment(data.start).format("DD.MM.YYYY");
                popupTimeRef.current.innerHTML =
                  "Interval: " +
                  Moment(data.start).format("LT") +
                  " - " +
                  Moment(data.end).format("LT");
                setPopupEndDateValue(data.end);
                setPopupStartDateValue(data.start);
                console.log(Moment(data.start).format("DD.MM.YYYY"));
                console.log(Moment(data.start).format("HH:mm"));
              }}
              ref={calendarRef}
              duration={"3 days"}
              events={events}
              slotMinTime="08:00"
              slotMaxTime="18:00"
              height={"1000px"}
              eventClick={(arg) => {
                // arg.event.remove();
                // calendarRef.current.getApi().remove(arg.event);
                popupDateRef.current.innerHTML =
                  "Data: " + Moment(arg.event.start).format("DD.MM.YYYY");
                popupTimeRef.current.innerHTML =
                  "Interval: " +
                  Moment(arg.event.start).format("LT") +
                  " - " +
                  Moment(arg.event.end).format("LT");
                console.log(arg.event.extendedProps);
                console.log(calendarRef.current.getApi().addEvent(events[0]));
                console.log(events);
                setEvent(arg.event);
                setSelectedEventId(arg.event.extendedProps._id);
                setAvailableOffline(arg.event.extendedProps.availableOffline);
                setAvailableOnline(arg.event.extendedProps.availableOnline);
                console.log(availableOffline);
                console.log(availableOnline);
                // calendarRef.current.getApi().changeView("timeGridWeek");
                setShowPopup(true);
              }}
              contentHeight={"auto"}
              month="2-digit"
              // eventMinHeight={70}
              allDaySlot={false}
              // hiddenDays={[2, 4]}
              slotDuration="0:15:00"
              // eventClick={(arg) => {
              //   return (
              //     <>
              //       <div>
              //         <div>Programare</div>
              //         <div>
              //           <p>Data:</p>
              //         </div>
              //       </div>
              //     </>
              //   );
              // }}
              eventDataTransform={(eventData) => {
                if (eventData.extendedProps.location == "Online")
                  eventData.color = "rgba(248, 154, 118,0.3)";
                else if (eventData.extendedProps.location == "Cabinet")
                  eventData.color = "rgba(11, 30, 57,0.15)";
                eventData.classNames = "pl-3";
                return eventData;
              }}
              dayHeaderContent={(arg) => {
                const day = arg.date.getUTCDay();
                var dayToString = "";
                switch (day) {
                  case 0:
                    dayToString = "LUNI";
                    break;
                  case 1:
                    dayToString = "MARTI";
                    break;
                  case 2:
                    dayToString = "MIERCURI";
                    break;
                  case 3:
                    dayToString = "JOI";
                    break;
                  case 4:
                    dayToString = "VINERI";
                    break;
                  case 5:
                    dayToString = "SAMBATA";
                    break;
                  case 6:
                    dayToString = "DUMINICA";
                    break;
                }
                return (
                  <>
                    <div className="py-4">
                      <div className="font-openSans font-bold text-xs text-light-blue">
                        <p>{dayToString}</p>
                      </div>
                      <div className="mt-1">
                        <p className="text-3xl font-openSans font-bold text-light-blue">
                          {arg.date.getDate()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }}
              eventContent={(arg) => {
                return (
                  <>
                    <div className="text-secondary h-42">
                      <div className="font-openSans text-xs">
                        <p>Sedinta {arg.event.extendedProps.location}</p>
                      </div>
                      <div>
                        <p className="font-bold font-openSans text-base">
                          {arg.event.extendedProps.name}
                        </p>
                      </div>
                      <div>
                        <p>
                          {arg.event.start.getHours()}:
                          {arg.event.start.getMinutes() > 10
                            ? arg.event.start.getMinutes()
                            : "0" + arg.event.start.getMinutes()}{" "}
                          - {arg.event.end.getHours()}:
                          {arg.event.end.getMinutes() > 10
                            ? arg.event.end.getMinutes()
                            : "0" + arg.event.end.getMinutes()}
                        </p>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
