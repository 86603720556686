import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { LoadingScreen } from "./components";
import {
  Contact,
  Dashboard,
  Homepage,
  Login,
  LostPassword,
  PageNotFound,
  Register,
  RegisterSpecialist,
  SetariCont,
  Review,
  Rules,
  DespreNoi,
  CumFunctioneaza,
  Faq,
  Tips,
  Rapoarte,
  IstoricSedinte,
  Calendar,
  Sedinta,
  Blog,
  BlogPost,
  SetariContClient,
  AfisareContClient,
  SpecialistVisualizare,
  VizualizareSpecialisti,
  IstoricSedinteClient,
  SedintaClient,
  SetariContAdmin,
  SpecialistiAdmin,
  VeziProfilSpecialist,
  VeziClienti,
  VeziCompanii,
  AngajatiCompanie,
  Stripe2,
  TestStripe,
  CancelPay,
  SuccesPay,
  SuccesPayCompany,
} from "./pages";
import VeziSpecialistAdmin from "./pages/Admin/VeziSpecialistAdmin/VeziSpecialistAdmin";
import CalendarSpecialist from "./pages/Client/Calendar/Calendar";
import Angajati from "./pages/Companie/Angajati/Angajati";
import Tranzactii from "./pages/Companie/Tranzactii/Tranzactii";
import VeziAngajat from "./pages/Companie/VeziAngajat/VeziAngajat";
import ContulMeu from "./pages/Specialist/ContulMeu/Contulmeu";
import ContactFeedback from "./pages/Companie/ContactFeedback/ContactFeedback";
import HeaderCesarom from "./pages/Testui/TestUi";
import { LoadingContext } from "./providers/LoadingProvider";
import { UserContext } from "./providers/UserProvider";
import {
  ProtectedRoute,
  ProtectedRouteLogged,
  ProtectedRouteLoggedSpecialist,
  ProtectedRouteLoggedClient,
  ProtectedRouteLoggedAdmin,
} from "./utils/ProtectedRoute";

function App() {
  const [loading] = useContext(LoadingContext);
  const { userType } = useContext(UserContext);

  useEffect(() => {
    console.log(userType);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/despre-noi" exact element={<DespreNoi />} />
          <Route path="/cum-functioneaza" exact element={<CumFunctioneaza />} />
          <Route path="/tips" exact element={<Tips />} />
          <Route path="contact" element={<Contact />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog-post/:id" element={<BlogPost />} />
          <Route path="faq" element={<Faq />} />
          <Route
            path="login"
            element={
              <ProtectedRouteLogged>
                <Login />
              </ProtectedRouteLogged>
            }
          />
          <Route
            path="register"
            element={
              <ProtectedRouteLogged>
                <Register />
              </ProtectedRouteLogged>
            }
          />
          <Route
            path="register-specialist"
            element={
              <ProtectedRouteLogged>
                <RegisterSpecialist />
              </ProtectedRouteLogged>
            }
          />
          <Route
            path="lost-password"
            element={
              <ProtectedRouteLogged>
                <LostPassword />
              </ProtectedRouteLogged>
            }
          />

          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="dashboard/specialist/setari-cont"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <SetariCont />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/review"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <Review />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/rules"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <Rules />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/contul-meu"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <ContulMeu />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/rapoarte"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <Rapoarte />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/istoric-sedinte"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <IstoricSedinte />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/sedinta/:id"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <Sedinta />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/specialist/calendar"
            element={
              <ProtectedRouteLoggedSpecialist userType={userType}>
                <Calendar />
              </ProtectedRouteLoggedSpecialist>
            }
          />
          <Route
            path="dashboard/client/setari-cont"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <SetariContClient />
              </ProtectedRouteLoggedClient>
            }
          />

          <Route
            path="dashboard/client/tranzactii"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <Tranzactii />
              </ProtectedRouteLoggedClient>
            }
          />

          <Route
            path="dashboard/client/contact"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <ContactFeedback />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/admin/setari-cont"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <SetariContAdmin />
              </ProtectedRouteLoggedAdmin>
            }
          />
          <Route
            path="dashboard/admin/specialist/:id/afisare-specialist"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <VeziSpecialistAdmin />
              </ProtectedRouteLoggedAdmin>
            }
          />

          <Route path="cesarom" element={<HeaderCesarom />} />
          <Route
            path="dashboard/admin/companie/:id/afisare-companie"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <AngajatiCompanie />
              </ProtectedRouteLoggedAdmin>
            }
          />

          <Route path="/stripe2" element={<TestStripe />} />
          <Route path="/stripe/:appId/:loc" element={<Stripe2 />} />
          <Route
            path="/dashboard/client/succes-plata/:appId/:loc"
            element={<SuccesPay />}
          />
          <Route
            path="/dashboard/client/succes-plata-companie"
            element={<SuccesPayCompany />}
          />
          <Route
            path="/dashboard/client/cancel-plata"
            element={<CancelPay />}
          />
          <Route
            path="dashboard/admin/specialist/:id/profil-specialist"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <VeziProfilSpecialist />
              </ProtectedRouteLoggedAdmin>
            }
          />
          <Route
            path="dashboard/admin/specialisti"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <SpecialistiAdmin />
              </ProtectedRouteLoggedAdmin>
            }
          />
          <Route
            path="dashboard/admin/clienti"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <VeziClienti />
              </ProtectedRouteLoggedAdmin>
            }
          />
          <Route
            path="dashboard/admin/companii"
            element={
              <ProtectedRouteLoggedAdmin userType={userType}>
                <VeziCompanii />
              </ProtectedRouteLoggedAdmin>
            }
          />
          <Route
            path="dashboard/client/afisare-cont"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <AfisareContClient />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/specialist/:id/calendar"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <CalendarSpecialist />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/specialist/:id/afisare-specialist"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <SpecialistVisualizare />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/sedinta/:id"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <SedintaClient />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/vezi-angajat/:id"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <VeziAngajat />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/afisare-specialisti"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <VizualizareSpecialisti />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/angajati"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <Angajati />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route
            path="dashboard/client/istoric-sedinte"
            element={
              <ProtectedRouteLoggedClient userType={userType}>
                <IstoricSedinteClient />
              </ProtectedRouteLoggedClient>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
