import React, { useContext, useEffect } from "react";
import { Footer, Header, Seo } from "../../components";
import { UserContext } from "../../providers/UserProvider";
const Tips = () => {
  const { userType } = useContext(UserContext);
  useEffect(() => {
    console.log(userType);
  }, []);
  return (
    <>
      <Seo title="Acasa" description="Pagina de acasa" />
      <Header />
      Tips
      <Footer />
    </>
  );
};
export default Tips;
