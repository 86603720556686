import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { auth } from "../../api";
import { getAppoiments } from "../../api/getAppoiments.api";
import ViewMoreSedinta from "./ViewMoreSedinta";
import Moment from "moment";
import LoadingComponent from "../LoadingScreen/LoadingComponent";
import { ElementScrollController } from "@fullcalendar/react";

const TabelaIstoricSedinte = (props) => {
  const [sedinte, setSedinte] = useState();
  const [loading, setLoading] = useState(false);
  const [coloane, setColoane] = useState([]);
  const [locationFilterOnline, setLocationFilterOnline] = useState(
    props.locationFilterOnline
  );
  const [locationFilterOffline, setLocationFilterOffline] = useState(
    props.locationFilterOffline
  );
  var count = 0;

  useEffect(() => {
    // setLocationFilterOnline(props.locationFilterOnline);
    setLoading(true);
    console.log(props);
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoiments(idToken).then((res) => {
          var filtered = res.data.appointments;
          console.log(filtered);
          if (
            (props.locationFilterOnline == true &&
              props.locationFilterOffline == true) ||
            (props.locationFilterOnline == false &&
              props.locationFilterOffline == false)
          ) {
            console.log(props.locationFilterOffline);
            console.log(props.locationFilterOnline);
          } else if (props.locationFilterOffline) {
            console.log("äici2");
            filtered = res.data.appointments.filter((app) => {
              return app.location == "offline";
            });
          } else if (props.locationFilterOnline) {
            console.log("äici3");
            filtered = res.data.appointments.filter((app) => {
              return app.location == "online";
            });
          }

          let sortedAsceding = filtered.sort((a, b) => {
            return Moment(a.date) - Moment(b.date);
          });
          setSedinte(res.data.appointments);
          console.log(res.data.appointments);
          setColoane(props.columns);

          setLoading(false);
        });
      });
  }, [props.locationFilterOffline, props.locationFilterOnline]);

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <div>
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-b-lg font-raleway font-normal text-dark-blue text-sm">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {coloane.map((col) => (
                    <th
                      scope="col"
                      className={
                        (col.hidden == true
                          ? "hidden " + col.hiddenWidth + "table-cell "
                          : "") + "py-3.5 pl-4 pr-3 text-left font-bold sm:pl-6"
                      }
                    >
                      {col.nume}
                    </th>
                  ))}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white space-y-2">
                {/* {!locationFilterOnline
                  ? "":(sedinte = sedinte.filter((app) => {
                      return app.location == "online";
                    })):""} */}
                {!sedinte
                  ? "<h1>test<h2>"
                  : sedinte.map((sed) => {
                      if (Moment(sed.date).format() < Moment().format())
                        count++;
                      // console.log(sed.date);
                      // console.log(Moment());
                      if (Moment(sed.date).format() < Moment().format())
                        return (
                          <>
                            <tr>
                              {/* {Object.entries(sed).map(([key, value]) => (
                      <td
                      // className={
                      //   (sed.hiddenFields.includes(key)
                      //     ? "hidden " + sed.hiddenWidth + "table-cell "
                      //     : "") + "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                      // }
                      >
                        
                      </td>
                    ))} */}
                              <td
                                className={
                                  "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {count}
                              </td>
                              <td
                                className={
                                  "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {Moment(sed.date).format("DD-MM-YY")}
                              </td>
                              <td
                                className={
                                  "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {sed.startHour + " - " + sed.endHour}
                              </td>
                              <td
                                className={
                                  "whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {sed.clientFirstName}
                              </td>
                              <td
                                className={
                                  "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {sed.location}
                              </td>

                              <td
                                className={
                                  "hidden lg:table-cell whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6"
                                }
                              >
                                {sed.price + " RON"}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right font-medium sm:pr-6">
                                <Link
                                  to={
                                    "/dashboard/specialist/sedinta/" + sed._id
                                  }
                                >
                                  Vezi Sedinta
                                  {/* <span className="sr-only">, {sed.nr}</span> */}
                                  <span className="sr-only"></span>
                                </Link>
                              </td>
                            </tr>

                            {/* <tr>
                    <td colspan={sedinte.length + 1}>
                      <ViewMoreSedinta />
                    </td>
                  </tr> */}
                          </>
                        );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TabelaIstoricSedinte;
