import React from "react";

const FiltruSedinte = (props) => {
  return (
    <>
      <div>
        <div className="mb-3">
          <p className="text-lg font-bold">Filtreaza Istoric</p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="left basis-1/6 font-bold">
              {/* <h3 className="text-dark-blue pb-2.5">Denumire Client</h3>
              <input
                className="mb-4 max-w-full border rounded-md h-9 text-lg px-3"
                type="text"
              /> */}
              <div
              // className={`bg-white rounded-xl ${
              //   buttonShow === true ? "hidden" : ""
              // } sm:flex flex-col p-5 shadow-lg`}
              >
                {/* <h3 className="text-dark-blue pb-2.5">Data</h3>
                <div className="items-center gap-1 pb-6">
                  <input
                    className="w-full border rounded-md h-9 text-base px-3"
                    type="date"
                    onChange={props.onChangeStartDate}
                  />

                  <input
                    className="w-full mt-2 border rounded-md h-9 text-base px-3"
                    type="date"
                  />
                </div> */}
                {/* <h3 className="text-dark-blue pb-2.5 ">Organizeaza:</h3>
                <div className="flex flex-col gap-2.5 text-dark-blue pb-8">
                  <div className="flex items-center gap-2.5">
                    <input
                      name="recente"
                      type="checkbox"
                      className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Cele mai recente</span>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <input
                      name="vechi"
                      type="checkbox"
                      className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Cele mai vechi</span>
                  </div>
                </div> */}
                <h3 className="text-dark-blue pb-2.5 ">Tipologie</h3>
                <div className="flex flex-col gap-2.5 text-dark-blue">
                  <div className="flex items-center gap-2.5">
                    <input
                      name="online"
                      onClick={(e) => {
                        if (e.target.checked) {
                          console.log("da");
                          props.setLocationFilterOnline(true);
                        } else {
                          console.log("nu");
                          props.setLocationFilterOnline(false);
                        }
                      }}
                      type="checkbox"
                      className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Sedinte Online</span>
                  </div>
                  <div className="flex items-center gap-2.5">
                    <input
                      name="cabinet"
                      type="checkbox"
                      onClick={(e) => {
                        if (e.target.checked) {
                          console.log("da");
                          props.setLocationFilterOffline(true);
                        } else {
                          console.log("nu");
                          props.setLocationFilterOffline(false);
                        }
                        // props.setLocationFilterOffline(true);
                      }}
                      className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                    />
                    <span>Sedinte la Cabinet</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltruSedinte;
