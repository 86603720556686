import React, { useContext, useEffect, useState } from "react";
import { Footer, Header, Seo } from "../../components";
import Hero from "../../components/Hero/Hero";
import { Link } from "react-router-dom";
import desprenoi from "../../assets/imgdesprenoi/image_despre_noi.png";
import { UserContext } from "../../providers/UserProvider";
import ContentImage from "../../components/ContentImage/ContentImage";
import mission from "../../assets/imgdesprenoi/mission.jpg";
import Testimoniale from "../../components/Testimoniale/Testimoniale";
import image_girl from "../../assets/imgdesprenoi/John doe.jpg";
import hourglass from "../../assets/homepage/hourglass_orange.svg";
import { getSpecialistsHp } from "../../api/getSpecialist.api";
import { StarIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const services = [
  {
    image: hourglass,
    title: "OPTIMISMUL",
    paragraph:
      "Prima valoare este optimismul, credem că oamenii se pot schimba și că au resursele necesare să o facă.",
  },
  {
    image: hourglass,
    title: "CORECTITUDINEA",
    paragraph:
      "Corectitudinea ar fi a doua, doar trăind corect în toate aspectele vieții vei putea să trăiești în armonie cu ceilalți, vei construi lucruri de calitate și te vei simți bine în pielea ta.",
  },
  {
    image: hourglass,
    title: "EFICIENȚA",
    paragraph:
      "Eficiența ne caracterizează și ea, ne place să fim eficienți, să avem rezultate concrete și satisfăcătoare.",
  },
  {
    image: hourglass,
    title: "CREATIVITATEA",
    paragraph:
      "Lista valorilor noastre este mai lungă, dar ultima pe care o vom aminti aici este creativitatea, pentru că o viață trăită frumos se clădește și cu ajutorul ei, ne dezvoltăm ca oameni, fiind creativi.",
  },
];
const latestnew = [
  {
    image: image_girl,
    author: "John Doe",
    type: "Lorem ipsum dolor sit amet",
    paragraph: (
      <span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </span>
    ),
    fb: "#",
    in: "#",
    ld: "#",
  },
  {
    image: image_girl,
    author: "John Doe",
    type: "Lorem ipsum dolor sit amet",
    paragraph: (
      <span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </span>
    ),
    fb: "#",
    in: "#",
    ld: "#",
  },
  {
    image: image_girl,
    author: "John Doe",
    type: "Lorem ipsum dolor sit amet",
    paragraph: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
        <br />
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua
        </p>
      </>
    ),
    fb: "#",
    in: "#",
    ld: "#",
  },
];
const DespreNoi = () => {
  const { userType } = useContext(UserContext);
  const [specialists, setSpecialists] = useState();
  useEffect(() => {
    console.log(userType);
    getSpecialistsHp().then((res) => setSpecialists(res.specialists));
  }, []);
  return (
    <>
      <Seo title="Acasa" description="Pagina de acasa" />
      <Header />
      <Hero
        title="Despre Noi"
        background="desprenoi"
        image={desprenoi}
        imgback={true}
      />
      <ContentImage
        image={mission}
        elipse={true}
        title={"Hai să îți spunem câte ceva despre noi."}
        link="/register"
        text_link="Fa-ti cont gratuit"
        paragraph={
          <>
            <p>
              Solution Talk este o clinică virtuală de psihoterapie unde găsești
              în principal servicii de psihoterapie, dar și servicii de
              psihiatrie. Noi, cei care am creat acest proiect, suntem
              psihoterapeuți și apariția Solution Talk a venit în mod firesc, ca
              o continuare a evoluției noastre în acest domeniu plin de
              recompense, în care ne considerăm privilegiați că putem ajuta
              oamenii să își trăiască viața așa cum și-au dorit dintotdeauna.
              <br></br>
              <br></br>
              Suntem pasionați de psihoterapie și ne-am dedicat tot timpul și
              energia doar acestui domeniu. După ani de experiență și momente
              magice în care cei pe care i-am însoțit pe drumul schimbării au
              reușit să ajungă acolo unde și-au dorit, și nu doar să viseze cum
              ar fi dacă ar ajunge acolo, vrem să continuăm pe acest drum și să
              ajutăm cât mai mulți oameni să își îndeplinească visurile. Aceasta
              este misiunea noastră, să ajutăm un număr cât mai mare de oameni
              să trăiască o viață împlinită. <br></br>
              <br></br>Ca să ne putem îndeplini această misiune, ne vom ghida
              după valorile care ne reprezintă și care ne-au ajutat să evoluăm.
            </p>
          </>
        }
      />
      <section className=" bg-light-blue sm:bg-body-g sm:bg-back-valori bg-cover mt-10 sm:mt-28 bg-center px-10 sm:px-20 xl:px-52">
        <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  pb-8 xl:pb-48 pt-10 sm:pt-52 xl:pt-80">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-white uppercase pb-6 sm:pb-12">
            Valorile Noastre
          </h2>
          <div className=" pt-6 pb-4 sm:pb-0 sm:pt-10  flex flex-col items-center">
            <div className="flex flex-wrap  pb-10 sm:pb-20 gap-y-2 sm:gap-y-4  lg:gap-y-11 ">
              {services.map((service, index) => (
                <div
                  className="flex basis-full mx-auto sm:basis-1/2 lg:basis-1/3 xl:basis-1/4 px-4"
                  key={index}
                >
                  {/* <img class="max-w-20" src={service.image} /> */}
                  <div>
                    <h4 className="text-xl sm:text-2xl font-ralewaySemibold text-white uppercase pb-1">
                      {" "}
                      {service.title}
                    </h4>
                    <p className="text-sm font-ralewaySemibold text-white 2xl:pr-14">
                      {" "}
                      {service.paragraph}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="pt-10 ">
        <div className="flex flex-col items-center max-w-1677px m-auto px-5 pb-6  ">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Echipa noastră
          </h2>
          <div className=" w-full flex gap-6 sm:gap-6 pb-10 sm:pb-20 flex-col lg:flex-row">
            {latestnew.map((item, index) => (
              <div
                className="flex basis-1/3 bg-white  rounded-3xl flex-col sm:flex-row lg:flex-col 2xl:flex-row shadow-lg 2xl:items-start  py-9 px-5 gap-3"
                key={index}
              >
                <div className=" basis-1/2 lg:basis-full 2xl:basis-1/2 flex flex-col h-full justify-between">
                  <img src={item.image} className="pb-7" />
                  <div className=" hidden lg:flex social-media  gap-6 2xl:justify-between  ">
                    <Link to={item.fb}>
                      <svg
                        id="Facebook"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="37"
                        viewBox="0 0 30 37"
                      >
                        <rect
                          id="Rectangle_392"
                          data-name="Rectangle 392"
                          width="30"
                          height="37"
                          fill="none"
                        />
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M90.031,29.6V16.116h4.6l.658-5.262H90.031V7.564c0-1.48.493-2.631,2.631-2.631h2.8V.164C94.8.164,93.156,0,91.347,0,87.4,0,84.6,2.467,84.6,6.907v3.947H80v5.262h4.6V29.6Z"
                          transform="translate(-72.6 0.001)"
                          fill="#f89a76"
                          fillRule="evenodd"
                        />
                      </svg>
                    </Link>
                    <Link to={item.in}>
                      <svg
                        id="Instagram"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.6"
                        height="29.6"
                        viewBox="0 0 29.6 29.6"
                      >
                        <path
                          id="Path_3"
                          data-name="Path 3"
                          d="M14.8,2.631a45.38,45.38,0,0,1,5.92.164,7.627,7.627,0,0,1,2.8.493,5.775,5.775,0,0,1,2.8,2.8,7.627,7.627,0,0,1,.493,2.8c0,1.48.164,1.973.164,5.92a45.38,45.38,0,0,1-.164,5.92,7.627,7.627,0,0,1-.493,2.8,5.775,5.775,0,0,1-2.8,2.8,7.627,7.627,0,0,1-2.8.493c-1.48,0-1.973.164-5.92.164A45.38,45.38,0,0,1,8.88,26.8a7.627,7.627,0,0,1-2.8-.493,5.775,5.775,0,0,1-2.8-2.8,7.627,7.627,0,0,1-.493-2.8c0-1.48-.164-1.973-.164-5.92A45.38,45.38,0,0,1,2.8,8.88a7.627,7.627,0,0,1,.493-2.8A5.906,5.906,0,0,1,4.44,4.44,2.78,2.78,0,0,1,6.084,3.289,7.627,7.627,0,0,1,8.88,2.8a45.38,45.38,0,0,1,5.92-.164M14.8,0A48.589,48.589,0,0,0,8.716.164,10.152,10.152,0,0,0,5.1.822,6.438,6.438,0,0,0,2.467,2.467,6.438,6.438,0,0,0,.822,5.1,7.493,7.493,0,0,0,.164,8.716,48.589,48.589,0,0,0,0,14.8a48.589,48.589,0,0,0,.164,6.084A10.152,10.152,0,0,0,.822,24.5a6.438,6.438,0,0,0,1.644,2.631A6.438,6.438,0,0,0,5.1,28.778a10.152,10.152,0,0,0,3.618.658A48.588,48.588,0,0,0,14.8,29.6a48.588,48.588,0,0,0,6.084-.164,10.152,10.152,0,0,0,3.618-.658A6.9,6.9,0,0,0,28.778,24.5a10.152,10.152,0,0,0,.658-3.618c0-1.644.164-2.138.164-6.084a48.588,48.588,0,0,0-.164-6.084A10.152,10.152,0,0,0,28.778,5.1a6.438,6.438,0,0,0-1.644-2.631A6.438,6.438,0,0,0,24.5.822,10.152,10.152,0,0,0,20.884.164,48.589,48.589,0,0,0,14.8,0m0,7.236A7.443,7.443,0,0,0,7.236,14.8,7.564,7.564,0,1,0,14.8,7.236m0,12.5A4.845,4.845,0,0,1,9.867,14.8,4.845,4.845,0,0,1,14.8,9.867,4.845,4.845,0,0,1,19.733,14.8,4.845,4.845,0,0,1,14.8,19.733M22.693,5.1A1.809,1.809,0,1,0,24.5,6.907,1.825,1.825,0,0,0,22.693,5.1"
                          fill="#f89a76"
                          fillRule="evenodd"
                        />
                      </svg>
                    </Link>
                    <Link to={item.ld}>
                      <svg
                        id="XMLID_801_"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.992"
                        height="29.926"
                        viewBox="0 0 29.992 29.926"
                      >
                        <path
                          id="XMLID_802_"
                          d="M11.432,99.73H5.411a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.021a.484.484,0,0,0,.484-.484V100.214A.484.484,0,0,0,11.432,99.73Z"
                          transform="translate(-4.45 -90.114)"
                          fill="#f89a76"
                        />
                        <path
                          id="XMLID_803_"
                          d="M3.973.341A3.97,3.97,0,1,0,7.943,4.31,3.975,3.975,0,0,0,3.973.341Z"
                          transform="translate(0 -0.341)"
                          fill="#f89a76"
                        />
                        <path
                          id="XMLID_804_"
                          d="M118.2,94.761a7.072,7.072,0,0,0-5.29,2.221V95.725a.484.484,0,0,0-.484-.484H106.66a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.008a.484.484,0,0,0,.484-.484V105.5c0-3.225.876-4.481,3.124-4.481,2.448,0,2.643,2.014,2.643,4.647v9.4a.484.484,0,0,0,.484.484h6.01a.484.484,0,0,0,.484-.484v-10.61C125.9,99.663,124.981,94.761,118.2,94.761Z"
                          transform="translate(-95.904 -85.626)"
                          fill="#f89a76"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="basis-1/2 text-dark-blue">
                  <h4 className="font-ralewayBold text-3xl  uppercase pb-2.5">
                    {item.author}
                  </h4>
                  <h3 className="text-sm font-ralewayRegular pb-6">
                    {item.type}
                  </h3>
                  <div className=" flex lg:hidden social-media  gap-6 2xl:justify-between  ">
                    <Link to={item.fb}>
                      <svg
                        id="Facebook"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="37"
                        viewBox="0 0 30 37"
                      >
                        <rect
                          id="Rectangle_392"
                          data-name="Rectangle 392"
                          width="30"
                          height="37"
                          fill="none"
                        />
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M90.031,29.6V16.116h4.6l.658-5.262H90.031V7.564c0-1.48.493-2.631,2.631-2.631h2.8V.164C94.8.164,93.156,0,91.347,0,87.4,0,84.6,2.467,84.6,6.907v3.947H80v5.262h4.6V29.6Z"
                          transform="translate(-72.6 0.001)"
                          fill="#f89a76"
                          fillRule="evenodd"
                        />
                      </svg>
                    </Link>
                    <Link to={item.in}>
                      <svg
                        id="Instagram"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.6"
                        height="29.6"
                        viewBox="0 0 29.6 29.6"
                      >
                        <path
                          id="Path_3"
                          data-name="Path 3"
                          d="M14.8,2.631a45.38,45.38,0,0,1,5.92.164,7.627,7.627,0,0,1,2.8.493,5.775,5.775,0,0,1,2.8,2.8,7.627,7.627,0,0,1,.493,2.8c0,1.48.164,1.973.164,5.92a45.38,45.38,0,0,1-.164,5.92,7.627,7.627,0,0,1-.493,2.8,5.775,5.775,0,0,1-2.8,2.8,7.627,7.627,0,0,1-2.8.493c-1.48,0-1.973.164-5.92.164A45.38,45.38,0,0,1,8.88,26.8a7.627,7.627,0,0,1-2.8-.493,5.775,5.775,0,0,1-2.8-2.8,7.627,7.627,0,0,1-.493-2.8c0-1.48-.164-1.973-.164-5.92A45.38,45.38,0,0,1,2.8,8.88a7.627,7.627,0,0,1,.493-2.8A5.906,5.906,0,0,1,4.44,4.44,2.78,2.78,0,0,1,6.084,3.289,7.627,7.627,0,0,1,8.88,2.8a45.38,45.38,0,0,1,5.92-.164M14.8,0A48.589,48.589,0,0,0,8.716.164,10.152,10.152,0,0,0,5.1.822,6.438,6.438,0,0,0,2.467,2.467,6.438,6.438,0,0,0,.822,5.1,7.493,7.493,0,0,0,.164,8.716,48.589,48.589,0,0,0,0,14.8a48.589,48.589,0,0,0,.164,6.084A10.152,10.152,0,0,0,.822,24.5a6.438,6.438,0,0,0,1.644,2.631A6.438,6.438,0,0,0,5.1,28.778a10.152,10.152,0,0,0,3.618.658A48.588,48.588,0,0,0,14.8,29.6a48.588,48.588,0,0,0,6.084-.164,10.152,10.152,0,0,0,3.618-.658A6.9,6.9,0,0,0,28.778,24.5a10.152,10.152,0,0,0,.658-3.618c0-1.644.164-2.138.164-6.084a48.588,48.588,0,0,0-.164-6.084A10.152,10.152,0,0,0,28.778,5.1a6.438,6.438,0,0,0-1.644-2.631A6.438,6.438,0,0,0,24.5.822,10.152,10.152,0,0,0,20.884.164,48.589,48.589,0,0,0,14.8,0m0,7.236A7.443,7.443,0,0,0,7.236,14.8,7.564,7.564,0,1,0,14.8,7.236m0,12.5A4.845,4.845,0,0,1,9.867,14.8,4.845,4.845,0,0,1,14.8,9.867,4.845,4.845,0,0,1,19.733,14.8,4.845,4.845,0,0,1,14.8,19.733M22.693,5.1A1.809,1.809,0,1,0,24.5,6.907,1.825,1.825,0,0,0,22.693,5.1"
                          fill="#f89a76"
                          fillRule="evenodd"
                        />
                      </svg>
                    </Link>
                    <Link to={item.ld}>
                      <svg
                        id="XMLID_801_"
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.992"
                        height="29.926"
                        viewBox="0 0 29.992 29.926"
                      >
                        <path
                          id="XMLID_802_"
                          d="M11.432,99.73H5.411a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.021a.484.484,0,0,0,.484-.484V100.214A.484.484,0,0,0,11.432,99.73Z"
                          transform="translate(-4.45 -90.114)"
                          fill="#f89a76"
                        />
                        <path
                          id="XMLID_803_"
                          d="M3.973.341A3.97,3.97,0,1,0,7.943,4.31,3.975,3.975,0,0,0,3.973.341Z"
                          transform="translate(0 -0.341)"
                          fill="#f89a76"
                        />
                        <path
                          id="XMLID_804_"
                          d="M118.2,94.761a7.072,7.072,0,0,0-5.29,2.221V95.725a.484.484,0,0,0-.484-.484H106.66a.484.484,0,0,0-.484.484v19.343a.484.484,0,0,0,.484.484h6.008a.484.484,0,0,0,.484-.484V105.5c0-3.225.876-4.481,3.124-4.481,2.448,0,2.643,2.014,2.643,4.647v9.4a.484.484,0,0,0,.484.484h6.01a.484.484,0,0,0,.484-.484v-10.61C125.9,99.663,124.981,94.761,118.2,94.761Z"
                          transform="translate(-95.904 -85.626)"
                          fill="#f89a76"
                        />
                      </svg>
                    </Link>
                  </div>
                  <span className="font-ralewayRegular text-lg">
                    {item.paragraph}
                  </span>
                </div>
              </div>
            ))}
          </div>
          <Link
            to="/register"
            className="whitespace-nowrap inline-flex items-center uppercase font-ralewayBold justify-center px-14 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
          >
            Fa-ti cont gratuit
          </Link>
        </div>
      </section> */}

      {/* <Testimoniale />
       */}
      <section className=" pt-10 sm:pt-10 py-20 pb-10 px-10 sm:px-20 xl:px-52">
        <div className="flex mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px flex-col items-center">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Vezi specialisti
          </h2>
          <div className=" flex pb-10 sm:pb-20 flex-wrap gap-4 sm:gap-0">
            {!specialists
              ? ""
              : specialists.slice(0, 3).map((item, index) => (
                  <div className=" basis-full lg:basis-1/3 sm:px-4" key={index}>
                    <div
                      className="flex flex-col rounded-3xl  shadow-lg pt-6 sm:pt-14 pb-12 px-6 2xl:px-6 items-start"
                      key={index}
                    >
                      <div className="pb-4 sm:pb-8 gap-2.5 items-center w-full">
                        <div className="rounded-full w-full h-80">
                          <img
                            src={item.avatarUrl}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="font-ralewayBold pt-9 pb-1 text-xl sm:text-3xl text-secondary">
                            {item.lastName + " " + item.firstName}
                          </h4>
                          <h3 className="font-ralewayRegular text-dark-blue text-sm sm:text-xl">
                            {item.specialistInfo.specializationType}
                          </h3>
                        </div>
                      </div>
                      <p className="font-ralewayRegular text-dark-blue text-sm sm:text-base  pb-2 sm:pb-7">
                        {item.description.slice(0, 150) + "[...]"}
                      </p>
                      <h3 className="text-lg text-dark-blue font-ralewayBold pb-2">
                        Specializari
                      </h3>
                      <div className="flex flex-wrap gap-1 sm:gap-3">
                        {item.specialistInfo.specializations.map((itm, ind) => (
                          <p
                            className=" text-xs font-ralewayMedium text-dark-blue px-2.5 sm:px-5 py-2 sm:py-3.5 bg-light-orange-w rounded-full"
                            key={ind}
                          >
                            {itm}
                          </p>
                        ))}
                      </div>
                      <h3 className="mt-3 text-lg text-dark-blue font-ralewayBold pb-2">
                        Rating
                      </h3>
                      <div className="flex gap-2 ">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              item.reviewsAverage > rating
                                ? "text-primary"
                                : "text-gray-300",
                              "flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-col 2xl:flex-row">
                        {/* <Link
                          to={item.showProfile}
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl	 px-4 sm:px-8 py-4 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                        >
                          Vezi Profil
                        </Link>
                        <Link
                          to={item.reservation}
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-8 py-4 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
                        >
                          Rezerva sedinta
                        </Link> */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {!userType ? (
            <Link
              to="/login"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          ) : (
            <Link
              to="/dashboard/client/afisare-specialisti"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};
export default DespreNoi;
