import React, { useState } from "react";
import { sendMail } from "../../api/mail";
import { ToastContainer, toast } from "react-toastify";

const FormContact = () => {
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  const [email, setEmail] = useState();
  const [company, setCompany] = useState();
  const [message, setMessage] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_TEST);
    console.log(process.env.REACT_APP_DEV_HOST);

    if (email != "" && lastName != "" && message != "") {
      const response = await sendMail({
        lastName,
        firstName,
        email,
        company,
        message,
      });
      console.log(response.msg);
      if (response.msg == "success") {
        setLastName("");
        setFirstName("");
        setEmail("");
        setCompany("");
        setMessage("");
        // setSuccess(true);
        toast.success("Mesaj trimis!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        // cleanFields();
      }
    } else {
      toast.error("Campuri incorecte!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="mt-10 sm:mt-0">
      <div className="">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form>
            <div className="shadow overflow-hidden sm:rounded-md font-ralewayMedium">
              <div className="pb-5 p-5 sm:p-10  bg-white">
                <h3 className="  text-2xl font-ralewayBold text-secondary uppercase pb-4 xl:pb-6">
                  Contactează-ne
                </h3>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="nume"
                      className="block text-base font-medium text-gray-700"
                    >
                      Nume
                    </label>
                    <input
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      name="nume"
                      id="nume"
                      autoComplete="nume"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3 "
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-base font-medium text-gray-700"
                    >
                      Prenume
                    </label>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      name="prenume"
                      id="prenume"
                      autoComplete="prenume"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-base font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="companie"
                      className="block text-base text-gray-700"
                    >
                      Companie
                    </label>
                    <input
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      type="text"
                      name="companie"
                      id="companie"
                      autoComplete="companie"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-12 px-3"
                    />
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="mesaj"
                      className="block text-base font-medium text-gray-700"
                    >
                      Mesaj
                    </label>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      name="mesaj"
                      id="mesaj"
                      autoComplete="mesaj"
                      className="mt-1 focus:ring-indigo-500  resize-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-inactive-text rounded-md h-52 p-3"
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 pb-8 bg-white text-right sm:px-6 flex justify-center">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className=" py-2 basis-full px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Trimite
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FormContact;
