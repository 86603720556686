import React, { useState } from "react";
import { Seo } from "../../components";
import firebase from "firebase/compat/app";
import logo from "../../assets/img/Solution_Talk_Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { forgotPasswordWithEmail } from "../../api/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LostPassword = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log(e.target.value);
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    forgotPasswordWithEmail(email);
  };

  return (
    <>
      <Seo
        title="Recuperare parola"
        description="Pagina de recuperare parola"
      />
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 font-raleway font-medium">
        <div className="sm:mx-auto sm:w-full sm:max-w-2xl mx-6 w-11/12">
          <Link to="/">
            <img className="mx-auto h-auto w-2/5" src={logo} alt="Workflow" />
          </Link>
          <h2 className="mt-6 text-center text-3xl font-ptSans font-bold  text-gray-900">
            Recuperare parola
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto md:w-full sm:max-w-2xl mx-6 w-11/12">
          <div className="bg-white md:py-16 py-10 px-8 shadow sm:rounded-lg md:px-16">
            <form className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-base text-gray-700"
                >
                  Adresa de email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleInputChange}
                    value={email}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex mb-4">
                <div className="w-1/2 "></div>
                <div className="text-sm w-1/2 text-right">
                  <Link
                    to="/login"
                    className="text-base text-primary hover:text-primary-hover"
                  >
                    Inapoi la autentificare
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={forgotPassword}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                >
                  Recupereaza parola
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LostPassword;
