import { useState } from "react";
import { decode } from "html-entities";
import { Link } from "react-router-dom";

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [terms, setTerms] = useState();

  const onChangeTerms = (e) => {
    e.preventDefault();
    if (e.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };
  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError("Please enter a valid email address");
      return null;
    }
    if (!terms) {
      setError("Va rugam sa acceptati termeni si condiitile!");
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });
    setEmail("");
    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  return (
    <>
      {/* <div className="d-flex newsletter-input-fields">
        <div className="mc-field-group">
          <input
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            type="email"
            placeholder="Your email"
            className="mr-2"
            onKeyUp={(event) => handleInputKeyEvent(event)}
          />
        </div>
        <div className="button-wrap wp-block-button">
          <button className="wp-block-button__link" onClick={handleFormSubmit}>
            Submit
          </button>
        </div>
      </div> */}

      <div className=" w-full sm:w-2/3 xl:w-full">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        ></label>
        <div className=" m-auto  mt-1 relative pb-2 sm:pb-5">
          <input
            onChange={(event) => setEmail(event?.target?.value ?? "")}
            onKeyUp={(event) => handleInputKeyEvent(event)}
            value={email}
            type="email"
            name="email"
            id="email"
            className="shadow-sm  py-2 sm:py-4 xl:py-6 2xl:py-8 pl-6 pr-48 sm:pl-12 text-lg sm:text-lg focus:ring-indigo-500 font-ralewayRegular focus:border-indigo-500 block w-full  border-gray-300 rounded-full "
            placeholder="Email *"
          />
          <button
            // to="#"
            onClick={handleFormSubmit}
            className="absolute right-2% top-10% 2xl:top-15% whitespace-nowrap inline-flex items-center font-ralewayRegular sm:font-ralewayBold uppercase justify-center text-sx xl:text-sm 2xl:text-lg  px-4 sm:px-16 py-1 sm:py-2.5 xl:py-4 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
          >
            Trimite
          </button>
        </div>
        <div className="flex items-center gap-2 sm:gap-5">
          <input
            type="checkbox"
            name="terms"
            id="terms"
            onChange={onChangeTerms}
          />
          <p className="text-xs sm:text-sm xl:text-lg text-white font-ralewayRegular ">
            Am citit si sunt de Acord cu Termenele si Conditiile si Politica de
            Confidentialitate
          </p>
        </div>
        <div className="newsletter-form-info text-white">
          {status === "sending" && <div>Se trimite...</div>}
          {status === "error" || error ? (
            <>
              <div
                className="newsletter-form-error text-red"
                dangerouslySetInnerHTML={{
                  __html: error || getMessage(message),
                }}
              />
            </>
          ) : null}
          {status === "success" && status !== "error" && !error && (
            <div dangerouslySetInnerHTML={{ __html: decode(message) }} />
          )}
        </div>
      </div>
    </>
  );
};

export default NewsletterForm;
