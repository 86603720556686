import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";
import { auth } from ".";

export const getAppoiments = async (idToken) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/getuserappointments`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return res;
  } catch (err) {
    console.log(err);
    if (err.response.status == 404) {
      return err.response;
    }
  }
};

export const getAppoimentsByPublicId = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/getuserappointmentsbypublicid/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(res);
    return response;
  } catch (err) {
    if (err.response.status == 404) {
      return err.response;
    }
  }
};

export const getAppoimentById = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/getuserappointmentbyid/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getspecialistAvailableSlots = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/getspecialistavailableslots/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getspecialistAppointments = async (idToken, id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/getspecialistappointments/${id}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    if (err.response.status == 404) {
      return err.response;
    }
    console.log(err);
  }
};
