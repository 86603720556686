import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import { toast } from "react-toastify";
import { cancelAppointmentByClient } from "../../../api/createAppoinment";
import { LoadingComponent } from "../../../components";
import { getSpecialists } from "../../../api/getSpecialist.api";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
// import { cancelAppointment } from "../../../../../backend/controllers/appointment.controllers";
const sedinte = [
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
];

const SpecialistiAdmin = () => {
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  const [loadingPast, setLoadingPast] = useState(true);
  const [specialisti, setSpecialisti] = useState();
  const [filterByName, setFilterByName] = useState();
  const [filterByCod, setFilterByCod] = useState();
  const [filterByType, setFilterByType] = useState([]);
  const [filterByTypePsihoterapeut, setFilterByTypePsihoterapeut] =
    useState(false);
  const [filterByTypePsihiatru, setFilterByTypePsihiatru] = useState(false);
  const [filterByTypeCoach, setFilterByTypeCoach] = useState(false);
  var popupRef = createRef();
  const numbers = [5, 4, 3, 2, 1];

  const onChangeFilterByType = (e) => {
    var tempArray = filterByType;
    if (e.target.checked) {
      if (e.target.name == "psihoterapeut") {
        setFilterByTypePsihoterapeut(true);
      } else if (e.target.name == "psihiatru") {
        setFilterByTypePsihiatru(true);
      } else if (e.target.name == "coach") {
        setFilterByTypeCoach(true);
      }
      tempArray.push(e.target.name);
    } else {
      if (e.target.name == "psihoterapeut") {
        setFilterByTypePsihoterapeut(false);
      } else if (e.target.name == "psihiatru") {
        setFilterByTypePsihiatru(false);
      } else if (e.target.name == "coach") {
        setFilterByTypeCoach(false);
      }
      tempArray = tempArray.filter((e2) => {
        console.log(e2 != e.target.name);
        return e2 != e.target.name;
      });
    }
    setFilterByType(tempArray);
    console.log(filterByType);
  };
  // const [apps, setApps] = useState([]);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getSpecialists(idToken).then((res) => {
          console.log(res);
          setSpecialisti(res.specialists);
          setLoadingPast(false);
        });
      });
  }, []);

  return (
    <>
      <HeaderAdmin />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          {/* <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Filtreaza
          </h4> */}
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Specialisti platforma
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          {/* <<<<<<< HEAD
======= */}

          {/* >>>>>>> 0babb37f75946dbc22c1538cd25411aa3acb71ad */}
          <h4 className="block 2xl:hidden col-span-4 2xl:col-span-6 font-openSans show-review text-dark-blue pt-4 text-lg flex items-center gap-4 font-bold">
            Specialisti platforma
          </h4>
          <div className=" pt-5 2xl:pt-0 h-auto col-span-7 2xl:col-span-7 flex flex-col overflow-hidden  items-start gap-4">
            {loadingPast ? (
              <LoadingComponent />
            ) : (
              <>
                <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
                  <li className="col-span-1">NR.</li>
                  <li className="col-span-2">NUME</li>
                  <li className="col-span-2">TIP</li>
                  <li className="col-span-2">LOCATIE</li>
                  <li className="col-span-2">COD</li>
                  <li className="col-span-2">APROBAT</li>
                  <li className="col-span-1">VEZI</li>
                </ul>
                {!specialisti
                  ? ""
                  : specialisti
                      .filter((sp) => {
                        var name = true;
                        var cod = true;
                        var typePsihoterapeut = false;
                        var typePsihiatru = false;
                        var typeCoach = false;
                        var typeDa = false;
                        var typeNu = false;
                        // console.log(filterByName);
                        if (filterByName) {
                          name = (sp.lastName + " " + sp.firstName)
                            .toLowerCase()
                            .includes(filterByName.toLowerCase());
                          // console.log(name);
                        }
                        console.log(filterByCod);
                        if (filterByCod) {
                          cod = sp.publicId
                            .toLowerCase()
                            .includes(filterByCod.toLowerCase());
                          console.log(cod);
                        }
                        // console.log("1 " + filterByTypePsihiatru);
                        // console.log("2 " + filterByTypePsihoterapeut);
                        // console.log("3 " + filterByTypeCoach);

                        if (
                          filterByTypeCoach == false &&
                          filterByTypePsihiatru == false &&
                          filterByTypePsihoterapeut == false
                        ) {
                          // console.log("aicisa");
                          typePsihoterapeut = true;
                          typePsihiatru = true;
                          typeCoach = true;
                        } else {
                          if (
                            filterByTypePsihoterapeut &&
                            sp.specialistInfo.specializationType.toLowerCase() ==
                              "psihoterapeut"
                          ) {
                            typePsihoterapeut = true;
                          } else if (
                            filterByTypePsihiatru &&
                            sp.specialistInfo.specializationType.toLowerCase() ==
                              "psihiatru"
                          ) {
                            typePsihiatru = true;
                          } else if (
                            filterByTypeCoach &&
                            sp.specialistInfo.specializationType.toLowerCase() ==
                              "coach"
                          ) {
                            typeCoach = true;
                          }

                          // typePsihiatru = filterByTypePsihiatru;
                          // typePsihoterapeut = filterByTypePsihoterapeut;
                          // typeCoach = filterByTypeCoach;
                        }

                        // console.log("1 " + typePsihiatru);
                        // console.log("2 " + typePsihoterapeut);
                        // console.log("3 " + typeCoach);
                        // console.log("1 " + filterByTypePsihiatru);
                        // console.log("2 " + filterByTypePsihoterapeut);
                        // console.log("3 " + filterByTypeCoach);
                        // console.log(filterByType);
                        // console.log(sp.specialistInfo.specializationType);
                        // if (filterByType.length != 0) {
                        //   type = filterByType.some(
                        //     (item) =>
                        //       item.toLowerCase() ==
                        //       sp.specialistInfo.specializationType.toLowerCase()
                        //   );
                        //   console.log(type);
                        // }
                        // console.log(name + " " + type);
                        console.log(
                          typePsihiatru || typePsihoterapeut || typeCoach
                        );
                        return (
                          name &&
                          (typePsihiatru || typePsihoterapeut || typeCoach) &&
                          cod
                        );
                      })
                      .map((sp, index) => (
                        <>
                          <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <li className="col-span-1">{index + 1}.</li>
                            <li className="col-span-2">
                              {sp.lastName + " " + sp.firstName}
                            </li>
                            <li className="col-span-2">
                              {sp.specialistInfo.specializationType}
                            </li>
                            <li className="col-span-2">{sp.city}</li>
                            <li className="col-span-2">{sp.publicId}</li>
                            <li className="col-span-2">
                              {!sp.approved ? "NU" : "DA"}
                            </li>
                            <li className="col-span-1">
                              <Link
                                to={
                                  "/dashboard/admin/specialist/" +
                                  sp.publicId +
                                  "/afisare-specialist"
                                }
                              >
                                VEZI
                              </Link>
                            </li>
                          </ul>
                          <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <div className=" grid grid-cols-2">
                              <span className="font-ralewayBold">NR.:</span>
                              {index + 1}.
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">NUME</span>
                              {sp.lastName + sp.firstName}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">TIP</span>
                              {sp.specialistInfo.specializationType}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">LOCATIE</span>
                              {sp.city}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">COD</span>
                              {sp.publicId}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">APROBAT</span>
                              {!sp.approved ? "NU" : "DA"}
                            </div>
                          </div>
                        </>
                      ))}
              </>
            )}
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  // setSelectedLocation("");
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                // onClick={cancelAppointment}
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialistiAdmin;
