import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { UserContext } from "../../providers/UserProvider";
const specializari = [
  "Bun",
  "foarte bun",
  "cel mai tare medic",
  "nr. 1 pe ilfov dar mai ales pe militari",
  "sa fie bine sa nu fie rau",
  "in padurea cu alune",
  "aveau casa 2 pitici",
  "vine pupza si spune",
  "vreau sa stau si eu aici",
];
const SpecializarileMele = (props) => {
  const { user } = useContext(UserContext);
  return (
    <>
      <div className="font-openSans flex flex-col flex-nowrap h-full">
        <div className="mb-3">
          <p className="text-lg font-bold text-secondary">
            Specializarile Mele
          </p>
        </div>
        <div className="bg-white overflow-hidden shadow rounded-lg h-full">
          <div className="px-4 py-5 sm:p-6">
            <div className="gap-5 flex flex-wrap font-raleway">
              {!user.user.specialistInfo.specializations
                ? ""
                : user.user.specialistInfo.specializations.map((spec) => (
                    <div className="px-5 py-3 rounded-full bg-primary-blur text-black w-fit text-xs">
                      <p>{spec}</p>
                    </div>
                  ))}
            </div>
            <div className="flex items-center justify-center mt-5">
              {/* <svg
                id="Component_4_1"
                data-name="Component 4 – 1"
                xmlns="http://www.w3.org/2000/svg"
                width="21.699"
                height="40"
                viewBox="0 0 21.699 40"
              >
                <text
                  id="_"
                  data-name="+"
                  transform="translate(3.875 32)"
                  fill="#f89a76"
                  font-size="34"
                  font-family="Raleway-Regular, Raleway"
                >
                  <tspan x="0" y="0">
                    +
                  </tspan>
                </text>
                <g
                  id="Ellipse_37"
                  data-name="Ellipse 37"
                  transform="translate(0 9.075)"
                  fill="none"
                  stroke="#f89a76"
                  stroke-width="2"
                >
                  <circle cx="10.85" cy="10.85" r="10.85" stroke="none" />
                  <circle cx="10.85" cy="10.85" r="9.85" fill="none" />
                </g>
              </svg> */}
              {/* <p className="inline-block text-primary font-raleway text-sm ml-1">
                Adauga specializari
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecializarileMele;
