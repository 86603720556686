import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../../api";
import {
  getAppoiments,
  getspecialistAppointments,
} from "../../../api/getAppoiments.api";
import Moment from "moment";
import { toast } from "react-toastify";
import { cancelAppointmentByClient } from "../../../api/createAppoinment";
import { LoadingComponent } from "../../../components";
import HeaderAdmin from "../../../components/Header/HeaderAdmin";
import {
  getCompanyById,
  getSpecialistById,
} from "../../../api/getSpecialist.api";
import { StarIcon } from "@heroicons/react/solid";
import { approveUser, getAngajati } from "../../../api/saveSettings.api";
// import { preventDefault } from "@fullcalendar/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// import { cancelAppointment } from "../../../../../backend/controllers/appointment.controllers";
const sedinte = [
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
];

const AngajatiCompanie = () => {
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [angajatiArray, setAngajatiArray] = useState();
  const [company, setCompany] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  const [loadingPast, setLoadingPast] = useState(true);
  const [filterByName, setFilterByName] = useState();
  const [approved, setApproved] = useState(false);
  const [specialist, setSpecialist] = useState();
  const { id } = useParams();
  var popupRef = createRef();

  const changeApproved = (e, approved2) => {
    e.preventDefault();
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        approveUser(idToken, id, approved2).then((res) => {
          console.log(res);
          setApproved(approved2);
          if (res.status == 200) {
            toast.success("Aprobarea contului a fost schimbata!", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      });
  };

  const cancelAppointment = (e) => {
    var now = Moment();
    var date = Moment(selectedAppointmentDate);
    var diff = date.diff(now);
    if (Moment.duration(diff).asHours() < 48) {
      toast.error(
        "Nu poti anula programarea cu mai putin de 48 de ore inainte!",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          cancelAppointmentByClient(selectedAppointment, idToken).then(
            (res) => {
              if (res.status == 200) {
                toast.success("Programarea a fost anulata cu succes!", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowPopup(false);

                var filtered = futureAppointments.filter((app) => {
                  return app._id != selectedAppointment;
                });
                setFutureAppointments(filtered);
              } else {
                toast.error("Eroare la anularea programarii!", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          );
        });
    }
  };
  // const [apps, setApps] = useState([]);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getCompanyById(idToken, id).then((res) => {
          console.log(res);
          setCompany(res.company);
          setApproved(res.company.approved);
        });
        getAngajati(idToken, id).then((res) => {
          console.log(res);
          setAngajatiArray(res.angajati);
        });
        // getspecialistAppointments(idToken, id).then((res) => {
        //   console.log(res);
        //   // setApps(res.appointments);
        //   let apps = [];
        //   let fapps = [];
        //   res.appointments.forEach((element) => {
        //     if (Moment(element.date) < Moment()) apps.push(element);
        //     else fapps.push(element);
        //   });
        //   setPastAppoiments(apps);
        //   setFutureAppointments(fapps);
        //   setLoadingPast(false);
        //   console.log(pastAppointments);
        //   console.log(futureAppointments);
        //   // setAppoiments(res);
        // });
      });
  }, []);

  return (
    <>
      <HeaderAdmin />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-11">
          <h4 className="col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Specialist
          </h4>
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-4 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Angajati companie
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-11 gap-0 2xl:gap-5 mb-10 text-sm">
          <div className="col-span-3">
            {" "}
            <div className="bg-white overflow-hidden shadow rounded-lg h-fit">
              <div className="px-4 py-5 sm:p-6">
                {!company ? (
                  ""
                ) : (
                  <div className="">
                    <div className="bg-white flex flex-col rounded-3xl  items-start">
                      <div className="flex w-full pb-2 sm:pb-4 gap-2.5 items-start">
                        <div className="rounded-full w-16 h-16">
                          <img
                            src={company.avatarUrl}
                            className="rounded-full w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="flex flex-col">
                          {/* <h5 className="text-primary font-bold text-base">
                            {!specialist.specialistInfo
                              ? ""
                              : specialist.specialistInfo.specializationType}
                          </h5> */}
                          <h4 className="font-ralewayBold text-xl text-secondary">
                            {company.lastName + " " + company.firstName}
                          </h4>
                          {/* <h3 className="font-ralewayRegular text-dark-blue text-sm ">
                            {!specialist.specialistInfo
                              ? ""
                              : specialist.specialistInfo.lastStudies}
                          </h3> */}
                          <p className="font-bold text-base mt-1">
                            {company.city}
                          </p>
                          {/* <div className="mt-1">
                            <div>
                              <div className=" flex items-center">
                                <span className="font-bold">
                                  {specialist.reviewsAverage}
                                </span>
                                <div>
                                  <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                      <StarIcon
                                        key={rating}
                                        className={classNames(
                                          specialist.reviewsAverage > rating
                                            ? "text-primary"
                                            : "text-gray-300",
                                          "flex-shrink-0 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ))}
                                  </div>
                                  <p className="sr-only">
                                    {specialist.reviewsAverage} out of 5 stars
                                  </p>
                                </div>
                                <p className="ml-2 text-sm text-gray-900">
                                  ({specialist.reviewsCount} reviews)
                                </p>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-row">
                        {approved ? (
                          <button
                            onClick={(e) => changeApproved(e, false)}
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Inchide contul
                          </button>
                        ) : (
                          <button
                            onClick={(e) => changeApproved(e, true)}
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Aproba contul
                          </button>
                        )}
                        {/* <Link
                          to={
                            "/dashboard/admin/specialist/" +
                            company.uid +
                            "/profil-specialist"
                          }
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
                        >
                          Vezi profil
                        </Link> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>{" "}
            {/* <h4 className="mt-5 hidden 2xl:block col-span-2 2xl:col-span-4 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
              Date financiare
            </h4>
            <div className="bg-white overflow-hidden shadow rounded-lg h-fit">
              <div className="px-4 py-5 sm:p-6">
                {!specialist ? (
                  ""
                ) : (
                  <div className="">
                    <div className="bg-white flex flex-col rounded-3xl  items-start">
                      <div className="flex w-full pb-2 sm:pb-4 gap-2.5 items-start">
                        <div className="rounded-full w-16 h-16">
                          <img
                            src={specialist.avatarUrl}
                            className="rounded-full w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="flex flex-col">
                          <h5 className="text-primary font-bold text-base">
                            {!specialist.specialistInfo
                              ? ""
                              : specialist.specialistInfo.specializationType}
                          </h5>
                          <h4 className="font-ralewayBold text-xl text-secondary">
                            {specialist.lastName + " " + specialist.firstName}
                          </h4>
                          <h3 className="font-ralewayRegular text-dark-blue text-sm ">
                            {!specialist.specialistInfo
                              ? ""
                              : specialist.specialistInfo.lastStudies}
                          </h3>
                          <p className="font-bold text-base mt-1">
                            {specialist.city}
                          </p>
                          <div className="mt-1">
                            <div>
                              <div className=" flex items-center">
                                <span className="font-bold">
                                  {specialist.reviewsAverage}
                                </span>
                                <div>
                                  <div className="flex items-center">
                                    {[0, 1, 2, 3, 4].map((rating) => (
                                      <StarIcon
                                        key={rating}
                                        className={classNames(
                                          specialist.reviewsAverage > rating
                                            ? "text-primary"
                                            : "text-gray-300",
                                          "flex-shrink-0 h-5 w-5"
                                        )}
                                        aria-hidden="true"
                                      />
                                    ))}
                                  </div>
                                  <p className="sr-only">
                                    {specialist.reviewsAverage} out of 5 stars
                                  </p>
                                </div>
                                <p className="ml-2 text-sm text-gray-900">
                                  ({specialist.reviewsCount} reviews)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-row">
                        {specialist.approved ? (
                          <Link
                            to={
                              "/dashboard/client/specialist/" +
                              specialist.uid +
                              "/afisare-specialist"
                            }
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Inchide contul
                          </Link>
                        ) : (
                          <Link
                            to={
                              "/dashboard/client/specialist/" +
                              specialist.uid +
                              "/afisare-specialist"
                            }
                            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                          >
                            Aproba contul
                          </Link>
                        )}
                        <Link
                          to={
                            "/dashboard/client/specialist/" +
                            specialist.uid +
                            "/calendar"
                          }
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm px-6 py-2 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
                        >
                          Vezi profil
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-8 flex flex-col overflow-hidden  items-start gap-4">
            <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
              <li className="col-span-2">NR.</li>
              <li className="col-span-2">NUME</li>
              <li className="col-span-2">ORAS</li>
              <li className="col-span-2">COD UTILIZATOR</li>
              <li className="col-span-2">EMAIL</li>
              {/* <li className="col-span-1">VEZI</li> */}
            </ul>
            {!angajatiArray
              ? ""
              : angajatiArray
                  //   .filter((sp) => {
                  //     var name = true;
                  //     var city = true;
                  //     var code = true;
                  //     if (filterByName) {
                  //       name = (sp.lastName + " " + sp.firstName)
                  //         .toLowerCase()
                  //         .includes(filterByName.toLowerCase());
                  //       console.log(name);
                  //     }
                  //     if (filterByCity) {
                  //       city = sp.city
                  //         .toLowerCase()
                  //         .includes(filterByCity.toLowerCase());
                  //     }
                  //     if (filterByCode) {
                  //       code = sp.publicId
                  //         .toLowerCase()
                  //         .includes(filterByCode.toLowerCase());
                  //     }
                  //     // console.log(filterByType);
                  //     // console.log(sp.specialistInfo.specializationType);
                  //     // if (filterByType.length != 0) {
                  //     //   type = filterByType.some(
                  //     //     (item) =>
                  //     //       item.toLowerCase() ==
                  //     //       sp.specialistInfo.specializationType.toLowerCase()
                  //     //   );
                  //     //   console.log(type);
                  //     // }
                  //     return name && code && city;
                  //   })
                  .map((ang, index) => (
                    <>
                      <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                        <li className="col-span-2">{index + 1}.</li>
                        <li className="col-span-2">
                          {ang.firstName + " " + ang.lastName}
                        </li>
                        <li className="col-span-2">{ang.city}</li>
                        <li className="col-span-2">{ang.publicId}</li>
                        <li className="col-span-2">{ang.email}</li>
                        {/* <li className="col-span-2">{sedinta.price}</li> */}
                        {/* <li className="col-span-1">
                          <Link
                            to={
                              "/dashboard/client/vezi-angajat/" + ang.publicId
                            }
                          >
                            VEZI
                          </Link>
                        </li> */}
                      </ul>
                      <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                        <div className=" grid grid-cols-2">
                          <span className="font-ralewayBold">NUMĂR:</span>
                          {index + 1}.
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">NUME:</span>
                          {ang.firstName + " " + ang.lastName}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">ORAS:</span>
                          {ang.city}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">
                            COD UTILIZATOR
                          </span>
                          {ang.publicId}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">EMAIL:</span>
                          {ang.email}
                        </div>
                        <div className="grid grid-cols-2">
                          <span className="font-ralewayBold">VEZI:</span>
                          {"Aici"}
                        </div>
                      </div>
                    </>
                  ))}
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  // setSelectedLocation("");
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                onClick={cancelAppointment}
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AngajatiCompanie;
