import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../../api";
import { getAppoimentById } from "../../api/getAppoiments.api";
import download from "../../assets/img/download-svgrepo-com.svg";

const profileImg =
  "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80";

const DetaliiSedinta = (props) => {
  const { id } = useParams();
  const [sedinta, setSedinta] = useState({
    clientFirstName: "",
    clientLastName: "",
  });
  useEffect(() => {
    console.log(id);
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          setSedinta(res.appointment);
          console.log(res.appointment);
        });
      });
  }, []);

  return (
    <>
      <div className="mb-3">
        <p className="text-lg font-bold font-openSans text-dark-blue">
          Detalii Sedinta
        </p>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg h-full">
        <div className="px-4 py-5 sm:px-10 sm:py-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-5 items-center px-2">
            <div className="flex gap-3">
              <div
                className="flex-shrink-0 inline-block rounded-full overflow-hidden h-32 w-32"
                aria-hidden="true"
              >
                <img
                  className="rounded-full w-full h-full object-center object-cover border-primary p-2 border-2"
                  src={sedinta.specialistAvatarUrl}
                  alt=""
                />
              </div>
              <div className="items-center m-auto text-secondary">
                <p className="font-openSans text-xl font-bold">
                  {sedinta.clientFirstName}
                </p>
                <p className="font-openSans text-lg font-bold">
                  {sedinta.location}
                </p>
              </div>
            </div>
            <div className="flex text-dark-blue">
              <div className="font-raleway text-xs m-auto">
                <div className="flex gap-2">
                  <p className="font-bold">DATA</p>
                  <p>{moment(sedinta.date).format("YYYY-MM-DD")}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-bold">INTERVAL ORAR</p>
                  <p>{sedinta.startHour + "-" + sedinta.endHour}</p>
                </div>
              </div>
              <div className="font-raleway text-xs m-auto">
                <div className="flex gap-2">
                  <p className="font-bold">LOCATIE</p>
                  <p>{sedinta.location}</p>
                </div>
                <div className="flex gap-2">
                  <p className="font-bold">COST</p>
                  <p>{sedinta.price}</p>
                </div>
              </div>
            </div>
            <div>
              <a className=" flex float-right items-end gap-1 xl:gap-3 text-xs font-raleway text-dark-blue">
                <img src={download} /> <span>Descarca Factura</span>
                {/* <span className="sr-only">, {com.link}</span> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetaliiSedinta;
