import React, { useContext, useEffect, useState } from "react";
import { Footer, Header, Seo } from "../../components";
import { UserContext, UserProvider } from "../../providers/UserProvider";
import { Link } from "react-router-dom";
import { PORT } from "../../utils/Constants";
import heroimage from "../../assets/homepage/hero_image.png";
import circle_white from "../../assets/homepage/circle_white.png";
import big_circle from "../../assets/homepage/big_circle.png";
import circle_left from "../../assets/homepage/circle_left.png";
import circle_right from "../../assets/homepage/circle_right.png";
import path_blue from "../../assets/homepage/path_blue.png";
import path_big_pink from "../../assets/homepage/path_big_pink.png";
import hourglass from "../../assets/homepage/hourglass.svg";
import client from "../../assets/homepage/client.png";
import specialist from "../../assets/homepage/specialist.png";
import companie from "../../assets/homepage/companie.png";
import person1 from "../../assets/homepage/Persona1.png";
import person2 from "../../assets/homepage/Persona2.png";
import person3 from "../../assets/homepage/Persona3.png";
import latest_new from "../../assets/homepage/latest_new.jpg";
import _ from "lodash";
import BackWithText from "../../components/HomePage/BackWithText";
import { getSpecialistsHp } from "../../api/getSpecialist.api";
import { StarIcon } from "@heroicons/react/solid";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "./NewsletterForm";
import { ToastContainer, toast } from "react-toastify";

const services = [
  {
    image: hourglass,
    title: "Psihoterapie",
    paragraph:
      "ședințe de psihoterapie individuală cu durata de 50 de minute și ședințe de cuplu sau familie cu durata de 90 de minute. Vei lucra împreună cu psihoterapeutul ales de tine, ca să depășești blocajele emoționale și comportamentale, pentru tulburări anxioase, depresie, tulburări de personalitate, probleme relaționale etc.",
  },
  {
    image: hourglass,
    title: "Psihiatrie",
    paragraph:
      "ședințe de psihiatrie cu durata de 50 de minute sau consult psihiatric cu durata de 25 de minute. Când simptomele sunt severe sau persistente, poți alege să discuți cu un medic psihiatru ori poți fi îndrumat către acesta de către psihoterapeutul tău. Psihiatrul te ajută să îți înțelegi problemele, face evaluări, pune diagnostice și oferă un tratament medicamentos.",
  },
];
const services2 = [
  {
    title: "Mauris non",
    paragraph:
      "Pellentesque ipsum, quis blandit nisi enim eget sapien. Nulla eget ligula eget nunc vestibulum ",
  },
  {
    title: "Mauris non",
    paragraph:
      "Pellentesque ipsum, quis blandit nisi enim eget sapien. Nulla eget ligula eget nunc vestibulum ",
  },
  {
    title: "Mauris non",
    paragraph:
      "Pellentesque ipsum, quis blandit nisi enim eget sapien. Nulla eget ligula eget nunc vestibulum ",
  },
  {
    title: "Mauris non",
    paragraph:
      "Pellentesque ipsum, quis blandit nisi enim eget sapien. Nulla eget ligula eget nunc vestibulum ",
  },
];

const register = [
  {
    image: client,
    link: "/register",
    title: "client",
    paragraph: [
      "Dacă vrei să devii client/pacient, primul pas este să îți faci un cont gratuit. Îți poți face cont doar cu o adresă de e-mail, te loghezi în cont, alegi un psihoterapeut sau psihiatru din listă, te programezi. Plătești în siguranță cu cardul și te vezi cu specialistul ales de tine online, de oriunde te-ai afla.",
    ],
  },
  {
    image: specialist,
    link: "/register-specialist",
    title: "specialist",
    paragraph: [
      "Dacă ești psihoterapeut atestat de Colegiul Psihologilor din România, pentru treapta minimă de psihoterapeut în supervizare, și vrei să te alături echipei noastre, fă-ți cont gratuit și hai să ne cunoaștem. Căutăm tot timpul psihoterapeuți talentați, cu experiență și pasionați de munca lor.",
      "Dacă ești medic psihiatru, membru al Colegiului Medicilor din România, ai experiență în evaluări psihiatrice, prescrierea de tratament psihofarmacologic și monitorizare ulterioară, precum și în colaborarea cu psihoterapeutul, fă-ți cont gratuit și hai să ne cunoaștem.",
    ],
  },
  {
    image: companie,
    link: "/register",
    title: "companie",
    paragraph: [
      "Dacă ești manager, antreprenor, conduci o societate comercială și vrei să oferi serviciile noastre de psihoterapie și psihiatrie angajaților tăi, sub formă de beneficii, fă-ți un cont gratuit și hai să ne cunoaștem. Vei avea suport dedicat, un cont special construit pentru tine, discounturi și alte beneficii.",
    ],
  },
];
const latestnew = [
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const experts = [
  {
    image: person1,
    name: "Nume Specialist",
    domain: "Lorem ipsum dolor sit amet",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    ],
    specialization: [
      "Lorem ipsum dolor",
      " Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      " Lorem ipsum dolor ",
    ],
    price: "100",
    rating: "3",
    showProfile: "/",
    reservation: "/",
  },
  {
    image: person2,
    name: "Nume Specialist",
    domain: "Lorem ipsum dolor sit amet",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    ],
    specialization: [
      "Lorem ipsum dolor",
      " Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      " Lorem ipsum dolor ",
    ],
    price: "100",
    rating: "4",
    showProfile: "/",
    reservation: "/",
  },
  {
    image: person3,
    name: "Nume Specialist",
    domain: "Lorem ipsum dolor sit amet",
    description: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    ],
    specialization: [
      "Lorem ipsum dolor",
      " Lorem ipsum dolor sit amet",
      "Lorem ipsum dolor sit amet",
      " Lorem ipsum dolor ",
    ],
    price: "100",
    rating: "5",
    showProfile: "/",
    reservation: "/",
  },
];
const Homepage = () => {
  const { userType } = useContext(UserContext);
  const [posts, setPosts] = useState();
  const [specialists, setSpecialists] = useState();
  const MAILCHIMP_URL =
    "https://solutiontalk.us9.list-manage.com/subscribe/post?u=30c01c8d83273adf6cc64fb08&amp;id=2faf8da833";
  useEffect(() => {
    console.log(userType);
    getSpecialistsHp().then((res) => setSpecialists(res.specialists));
    fetch("https://cms.solutiontalk.ro/wp-json/wp/v2/posts")
      .then((response) => response.json())
      .then((posts2) => {
        console.log(posts2);
        setPosts(posts2);
      });
  }, []);
  return (
    <>
      <Seo title="Acasa" description="Pagina de acasa" />
      <Header />
      {/* <h1>{process.env.REACT_APP_TEST}</h1> */}
      <section className="  sm:py-5 lg:py-14 xl:py-0 xl:h-600px 2xl:h-900px bg-no-repeat bg-cover bg-hero-back bg-white sm:bg-none px-10 sm:px-20 xl:px-52 ">
        <div className=" px-4 testui sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px mx-auto row py-20 bg-white/50  sm:py-0 flex items-center justify-end   h-full">
          <div className="col basis-full sm:basis-1/2 ">
            <h1 className=" text-4xl lg:text-5xl xl:text-7xl   font-ralewayBold text-secondary pb-5 lg:pb-6">
              PSIHOTERAPIE ONLINE{" "}
            </h1>
            {/* <h1>{process.env.TEST}</h1> */}

            <p className=" text-base lg:text-lg xl:text-xl  text-secondary font-ralewayRegular max-w-654px pb-4 lg:pb-6 ">
              Dacă ești în căutarea unui psihoterapeut cu care să vorbești
              online prin apel video, de oriunde te-ai afla, ești în locul
              potrivit. Te conectezi ușor cu telefonul tău, tableta, laptopul
              sau computerul.
            </p>
            <Link
              to="/login"
              className=" whitespace-nowrap inline-flex items-center font-ralewayBold text-sm lg:text-sm  uppercase justify-center px-5 lg:px-5 py-3  rounded-3xl shadow-lg  text-white bg-primary hover:bg-primary-hover"
            >
              FA-TI CONT GRATUIT
            </Link>
          </div>
          <div className="col  hidden  basis-1/2  relative  h-full sm:flex items-center">
            <img className="relative z-40 " src={heroimage} />
            <img className="absolute  z-30 2xl:top-28 " src={circle_white} />
            <img
              className="absolute  z-20 w-1/3 xl:w-1/2 top-0 -right-10 xl:-right-52"
              src={big_circle}
            />
            <img className="absolute  z-10 bottom-0 " src={circle_left} />
            <img
              className="absolute  z-10 bottom-0 -right-20"
              src={circle_right}
            />
          </div>
        </div>
      </section>
      <section className="bg-white pt-24 relative -z-10 sm:z-10 -mt-10 sm:mt-0 pb-10 lg:pb-0 px-10 sm:px-20 xl:px-52">
        <img
          className="absolute -left-5 sm:left-8 z-10 w-1/4 top-12 sm:top-0 xl:-top-20 2xl:-top-32"
          src={path_blue}
        />
        <img
          className="absolute -left-28 sm:-left-1/3 xl:-left-1/4 w-1/2  top-8 sm:-top-12 xl:-top-36 2xl:-top-48"
          src={path_big_pink}
        />
        <div className=" sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto px-6 pt-5">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase">
            CE SERVICII GĂSEȘTI <br></br>PE PLATFORMA SOLUTION TALK{" "}
          </h2>

          <p className="mt-5 text-center text-sm sm:text-base xl:text-base font-ralewayRegular text-secondary max-w-1356px m-auto">
            Solution Talk este o clinică virtuală de psihoterapie unde poți găsi
            psihoterapeuți și medici psihiatri atent selectați pentru experiența
            și abilitățile lor, atestați de Colegiul Psihologilor și de Colegiul
            Medicilor, care te pot ajuta să îți găsești starea de bine
            emoțională sau să îți atingi obiectivele pe care le dorești.
            Solution Talk este gândit ca un serviciu complet, ce acoperă prin
            specialiștii săi toate problemele emoționale și comportamentale cu
            care te confrunți.{" "}
          </p>
          <div className=" pt-6 pb-4 sm:pb-0 sm:pt-14 xl:pt-28 flex flex-col items-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 pb-10 sm:pb-20 gap-y-2 sm:gap-y-4 gap-x-20 lg:gap-y-11">
              {services.map((service, index) => (
                <div className="" key={index}>
                  {/* <img src={service.image} /> */}
                  <div>
                    <h4 className="text-center text-xl sm:text-2xl font-ralewaySemibold text-secondary uppercase pb-1">
                      {" "}
                      {service.title}
                    </h4>
                    <p className="text-center text-sm font-ralewaySemibold text-gray-p ">
                      {" "}
                      {service.paragraph}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <Link
              to="/cum-functioneaza"
              className="whitespace-nowrap inline-flex font-ralewayBold uppercase justify-center text-sm sm:text-xl items-center uppercase justify-center px-20 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
            >
              Află mai multe
            </Link>
          </div>
        </div>
      </section>
      <BackWithText
        title={"Cum Functioneaza"}
        services={services2}
        background="white"
      />
      <section className="bg-white pt-10 2xl:pt-24 px-10 sm:px-20 xl:px-52">
        <div className="flex sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  flex-col items-center ">
          <h2 className="text-center text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Inregistrare
          </h2>
          <div className=" w-full grid grid-cols-1 lg:grid-cols-3 gap-4 pb-10 sn:pb-20 ">
            {register.map((item, index) => (
              <div
                className="flex flex-col p-4 xl:p-8 rounded-3xl shadow-lg items-center"
                key={index}
              >
                <img src={item.image} />
                <h4 className="text-center font-ralewayBold pt-9 pb-4 uppercase text-2xl sm:text-3xl text-secondary">
                  {item.title}
                </h4>
                {item.paragraph.map((par, ind) => (
                  <p
                    className="pb-6 text-base sm:text-base font-ralewayRegular text-dark-blue"
                    key={ind}
                  >
                    {par}
                  </p>
                ))}
                <Link
                  to={item.link}
                  className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-sm	 px-6 xl:px-10 py-4 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
                >
                  Fa-ti cont gratuit
                </Link>
              </div>
            ))}
          </div>
          {/* <Link
            to="/"
            className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl	 px-6 sm:px-14 py-4 border border-light-orange text-light-orange rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
          >
            Vezi toti specialistii
          </Link> */}
        </div>
      </section>

      <section className="bg-white pt-0 sm:pt-10  px-10 sm:px-20 xl:px-52">
        <div className="flex mx-auto sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px flex-col items-center">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Vezi specialisti
          </h2>
          <div className=" flex pb-10 sm:pb-20 flex-wrap gap-4 sm:gap-0">
            {!specialists
              ? ""
              : specialists.slice(0, 3).map((item, index) => (
                  <div
                    className=" basis-full  lg:basis-1/3 sm:px-4"
                    key={index}
                  >
                    <div
                      className="flex flex-col rounded-3xl  shadow-lg pt-6 sm:pt-14 pb-12 px-6 2xl:px-6 items-start"
                      key={index}
                    >
                      <div className="pb-4 sm:pb-8 gap-2.5 items-center w-full">
                        <div className="rounded-full w-full h-80">
                          <img
                            src={item.avatarUrl}
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="flex flex-col">
                          <h4 className="font-ralewayBold pt-9 pb-1 text-xl sm:text-3xl text-secondary">
                            {item.lastName + " " + item.firstName}
                          </h4>
                          <h3 className="font-ralewayRegular text-dark-blue text-sm sm:text-xl">
                            {item.specialistInfo.specializationType}
                          </h3>
                        </div>
                      </div>
                      <p className="font-ralewayRegular text-dark-blue text-sm sm:text-base  pb-2 sm:pb-7">
                        {item.description.slice(0, 150) + "[...]"}
                      </p>
                      <h3 className="text-lg text-dark-blue font-ralewayBold pb-2">
                        Specializari
                      </h3>
                      <div className="flex flex-wrap gap-1 sm:gap-3">
                        {item.specialistInfo.specializations.map((itm, ind) => (
                          <p
                            className=" text-xs font-ralewayMedium text-dark-blue px-2.5 sm:px-5 py-2 sm:py-3.5 bg-light-orange-w rounded-full"
                            key={ind}
                          >
                            {itm}
                          </p>
                        ))}
                      </div>
                      <h3 className="mt-3 text-lg text-dark-blue font-ralewayBold pb-2">
                        Rating
                      </h3>
                      <div className="flex gap-2 ">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              item.reviewsAverage > rating
                                ? "text-primary"
                                : "text-gray-300",
                              "flex-shrink-0 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <div className="flex justify-between gap-2 flex-col sm:flex-row lg:flex-col 2xl:flex-row">
                        {/* <Link
                          to={item.showProfile}
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl	 px-4 sm:px-8 py-4 border border-primary text-secondary rounded-full shadow-lg duration-300  hover:bg-primary hover:text-white"
                        >
                          Vezi Profil
                        </Link>
                        <Link
                          to={item.reservation}
                          className="whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-8 py-4 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
                        >
                          Rezerva sedinta
                        </Link> */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {!userType ? (
            <Link
              to="/login"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          ) : (
            <Link
              to="/dashboard/client/afisare-specialisti"
              className="mb-10 whitespace-nowrap inline-flex items-center font-ralewayBold uppercase justify-center text-sm sm:text-xl px-4 sm:px-14 py-4 border border-light-orange text-dark-blue rounded-full shadow-lg duration-300 text-white hover:bg-light-orange hover:text-white"
            >
              Vezi toti specialistii
            </Link>
          )}
        </div>
      </section>
      <section className="bg-backnew px-10 sm:px-20 xl:px-52 py-40 sm:py-52 -mt-32 sm:-mt-20 xl:-mt-40  -mb-16 sm:mb-0 xl:-mb-20  2xl:mb-0 xl:py-80  bg-no-repeat bg-cover bg-center">
        <div className="flex flex-col items-center  px-4 sm:max-w-540px md:max-w-720px m-auto">
          <h3 className="text-white text-xl sm:text-3xl 2xl:text-4xl font-ralewayBold uppercase pb-2 2xl:pb-4">
            Abonare newsletter
          </h3>
          <p className=" text-sm sm:text-lg xl:text-lg text-white font-ralewayRegular max-w-800px m-auto text-center pb-4 sm:pb-8 2xl:pb-16">
            Când te abonezi la newsletterul nostru, afli primul despre ofertele
            și promoțiile pe care le oferim, cele mai noi articole și sfaturi
            pentru sănătate psihică și emoțională, toate, în contul tău de
            e-mail.
          </p>
          <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={(props) => {
              const { subscribe, status, message } = props || {};
              return (
                <NewsletterForm
                  status={status}
                  message={message}
                  onValidated={(formData) => {
                    subscribe(formData);
                  }}
                />
              );
            }}
          />
          {/* <div className=" w-full sm:w-2/3 xl:w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            ></label>
            <div className=" m-auto  mt-1 relative pb-2 sm:pb-5">
              <input
                type="email"
                name="email"
                id="email"
                className="shadow-sm  py-2 sm:py-4 xl:py-6 2xl:py-8 pl-6 pr-48 sm:pl-12 text-lg sm:text-2xl focus:ring-indigo-500 font-ralewayRegular focus:border-indigo-500 block w-full  border-gray-300 rounded-full "
                placeholder="Email *"
              />
              <Link
                to="#"
                className="absolute right-2% top-10% 2xl:top-15% whitespace-nowrap inline-flex items-center font-ralewayRegular sm:font-ralewayBold uppercase justify-center text-sx xl:text-sm 2xl:text-xl  px-4 sm:px-16 py-1 sm:py-2.5 xl:py-4 border bg-primary border-light-orange text-white rounded-full shadow-lg duration-300  hover:bg-primary-hover "
              >
                Trimite
              </Link>
            </div>
            <div className="flex items-center gap-2 sm:gap-5">
              <input type="checkbox" />
              <p className="text-xs sm:text-sm xl:text-lg text-white font-ralewayRegular ">
                Am citit si sunt de Acord cu Termenele si Conditiile si Politica
                de Confidentialitate
              </p>
            </div>
          </div> */}
        </div>
      </section>
      <div className="bg-white">
        <section className="bg-white px-10 sm:px-20 xl:px-52">
          <div className="flex flex-col items-center sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  pb-6 sm:pb-14 xl:pb-28">
            <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
              SFATURI DE SĂNĂTATE PSIHO-EMOȚIONALĂ
            </h2>
            {/* <p className="text-sm sm:text-lg xl:text-xl font-ralewayRegular text-secondary pb-10 sm:pb-20 text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              vitae consequat ante.
            </p> */}
            <div className=" grid grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-6 pb-10 sm:pb-20 ">
              {!posts
                ? ""
                : posts.slice(0, 3).map((item, index) => (
                    <>
                      <Link to={"/blog-post/" + item.id}>
                        <div
                          className="h-full flex flex-col  rounded-3xl shadow-lg items-start"
                          key={index}
                        >
                          <div className="w-full h-[236px] rounded-t-3xl">
                            <img
                              className="w-full h-full object-center object-cover rounded-t-3xl"
                              src={item.featured_image_url}
                            />
                          </div>
                          <div className="flex flex-col p-4 sm:p-9">
                            {/* <h3 className="text-primary sm:text-base font-ralewayBold">
                          {item.type}
                        </h3> */}
                            <div
                              className="text-xl font-bold mb-3"
                              dangerouslySetInnerHTML={{
                                __html: "<h3>" + item.title.rendered + "</h3>",
                              }}
                            ></div>
                            <div
                              className="text-lg"
                              style={({ fontWeight: 300 }, { fontSize: 15 })}
                              dangerouslySetInnerHTML={{
                                __html: item.excerpt.rendered,
                              }}
                            />
                            <div className="flex w-full pb-4 sm:pb-8 gap-2.5 items-center">
                              {/* <img width={58} src={item.image_author} /> */}
                              <div className="flex flex-col">
                                {/* <h4 className="font-ralewayBold  pb-1 text-lg text-secondary">
                          {item.author}
                        </h4>
                        <h3 className="font-ralewayRegular text-gray-primary text-sm sm:text-xl">
                          {item.date} | 7 min read
                        </h3> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </>
                  ))}
            </div>
            <Link
              to="/blog"
              className="whitespace-nowrap inline-flex items-center uppercase font-ralewayBold justify-center px-14 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
            >
              Citeste mai mult
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
