import { Fragment, React } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { HandleLogout } from "../../utils";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import logomini from "../../assets/img/logomini.png";
// const solutions = [
//   {
//     name: "Analytics",
//     description:
//       "Get a better understanding of where your traffic is coming from.",
//     href: "#",
//     icon: ChartBarIcon,
//   },
//   {
//     name: "Engagement",
//     description: "Speak directly to your customers in a more meaningful way.",
//     href: "#",
//     icon: CursorClickIcon,
//   },
//   {
//     name: "Security",
//     description: "Your customers' data will be safe and secure.",
//     href: "#",
//     icon: ShieldCheckIcon,
//   },
//   {
//     name: "Integrations",
//     description: "Connect with third-party tools that you're already using.",
//     href: "#",
//     icon: ViewGridIcon,
//   },
//   {
//     name: "Automations",
//     description:
//       "Build strategic funnels that will drive your customers to convert",
//     href: "#",
//     icon: RefreshIcon,
//   },
// ];
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];
const resources = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Despre noi",
    href: "/despre-noi",
  },
  {
    name: "Cum Functioneaza",
    href: "/cum-functioneaza",
  },
  {
    name: "Sfaturi",
    href: "/blog",
  },
  {
    name: "F.A.Q.",
    href: "/contact",
  },
  {
    name: "Contact",
    href: "/contact",
  },
];
const recentPosts = [
  { id: 1, name: "Boost your conversion rate", href: "#" },
  {
    id: 2,
    name: "How to use search engine optimization to drive traffic to your site",
    href: "#",
  },
  { id: 3, name: "Improve your customer experience", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({ background }) => {
  const { user } = useContext(UserContext);

  return (
    <Popover className={`relative ${background === true ? "" : "bg-white"}`}>
      <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px mx-auto px-4 ">
        <div className="flex justify-between items-center  pt-3 lg:py-5 lg:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Workflow</span>
              <img className=" w-auto " src={logomini} alt="Soluiton Talk" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 lg:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden lg:flex space-x-5 xl:space-x-10"
          >
            {/* --------------------------------ITEM WITH SUBMENU -----------------------------------
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    )}
                  >
                    <span>HOME</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                              >
                                <item.icon
                                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
              -------------------------------- END ITEM WITH SUBMENU ----------------------------------- */}
            {resources.map((item, index) => (
              <Link
                key={index}
                to={item.href}
                className={` ${
                  window.location.pathname === item.href
                    ? "font-bold"
                    : "font-regular"
                } text-base xl:text-lg  text-dark-blue hover:text-gray-500 uppercase`}
              >
                {item.name}
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0">
            {user && user.user ? (
              <Link
                to={
                  "/dashboard/" +
                  (user.user.accountType == "company"
                    ? "client"
                    : user.user.accountType) +
                  "/setari-cont"
                }
                className="ml-8 whitespace-nowrap inline-flex items-center uppercase justify-center px-12 py-2 border border-transparent rounded-full shadow-sm  text-white bg-primary hover:bg-primary-hover"
              >
                Contul meu
              </Link>
            ) : (
              <>
                {/* <Link
                  to="/login"
                  className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Autentificare
                </Link> */}
                <Link
                  to="/login"
                  className="ml-8 whitespace-nowrap inline-flex items-center uppercase justify-center px-12 py-2 border border-transparent rounded-full shadow-sm  text-white bg-primary hover:bg-primary-hover"
                >
                  LOG IN
                </Link>
              </>
            )}
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 z-50 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-3 px-5">
              <div className="flex items-center justify-between">
                <Link to="/">
                  <img className="h-8 w-auto" src={logomini} alt="Workflow" />
                </Link>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-2">
                <nav className="grid gap-y-8">
                  {/* {solutions.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))} */}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {resources.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div>
                <Link
                  to="/register"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-primary-hover"
                >
                  Inregistrare
                </Link>
                <p className="mt-6 text-center text-base font-medium text-gray-500 ">
                  Ai deja cont?
                  <Link
                    to="/login"
                    className="pl-2 text-primary hover:text-indigo-500"
                  >
                    Log in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Header;
