import React from "react";
import { HeaderClient } from "../../../components";
import FormContact from "../../../components/FormContact/FormContact";

const ContactFeedback = () => {
  return (
    <>
      <HeaderClient />
      <div className="sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto">
        <FormContact />
      </div>
    </>
  );
};

export default ContactFeedback;
