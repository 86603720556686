import React, { useContext, useEffect } from "react";
import { Footer, Header, Seo } from "../../components";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "react-router-dom";
import ContentImage from "../../components/ContentImage/ContentImage";
import BackWithText from "../../components/HomePage/BackWithText";
import Hero from "../../components/Hero/Hero";
import cumfunctioneaza from "../../assets/cumfunctioneaza/cum_functioneaza.png";
import platforma from "../../assets/img/platforma.jpg";
import iMac from "../../assets/img/iMac.png";
import BackWithText2 from "../../components/HomePage/BackWithText2";
const cum_functioneaza = [
  {
    title: "Lorem ipsum dolor sit amet adipiscing ",
    paragraph: (
      <>
        <p>
          Când te-ai hotărât să lucrezi cu unul dintre specialiștii noștri,
          primul pas este să îți creezi un cont, îți deschizi cont foarte ușor
          doar cu o adresă de e-mail.
        </p>
      </>
    ),
  },
  {
    title: "Lorem ipsum dolor sit amet adipiscing ",
    paragraph: (
      <>
        <p>
          Pasul următor este să vezi echipa noastră de specialiști și să alegi
          unul care crezi că se potrivește nevoilor tale. Îl selectezi
          consultând calendarul respectivului specialist ca să vezi în ce zile
          și la ce ore este disponibil și alegând să rezervi unul dintre aceste
          intervale.
        </p>
      </>
    ),
  },
  {
    title: "Lorem ipsum dolor sit amet adipiscing ",
    paragraph: (
      <>
        <p>
          După ce ai ales specialistul și ai făcut programarea, vei fi
          direcțrezervată doar după ce va fi achitată, vei primi un e-mail de
          confirmareionat către plata ședinței cu cardul bancar și vei putea
          achita ședința în deplină siguranță prin procesatorul de plăți.
          Ședința este cu data și ora când va fi ținută ședința.
        </p>
      </>
    ),
  },
  {
    title: "Lorem ipsum dolor sit amet adipiscing ",
    paragraph: (
      <>
        <p>
          În ziua și la ora stabilite programării, te conectezi cu
          psihoterapeutul sau cu medicul ales prin linkul primit pe e-mailul de
          confirmare, susții prima ședință și la sfârșitul ședinței stabilești
          împreună cu specialistul tău cum va continua colaborarea, tratamentul
          psihoterapeutic sau medicamentos.
        </p>
      </>
    ),
  },
];
const services2 = [
  {
    title: "TIMP SALVAT",
    paragraph:
      "Salvezi mult timp pentru că nu te mai deplasezi, timp pe care poți să îl folosești în alte feluri",
  },
  {
    title: "ACCESIBIL",
    paragraph:
      "Este accesibil, vorbești cu psihoterapeutul tău de oriunde, de acasă, de la birou, din vacanță etc. Poate te afli la distanță de un cabinet de psihologie, ai o problemă și nu poți să te deplasezi, vrei să îți extinzi aria de selecție a unui psihoterapeut sau nu vrei să te deplasezi, îți este mai ușor să faci ședința de acolo de unde te afli",
  },
  {
    title: "DISCREȚIE",
    paragraph:
      "Beneficiezi de discreție maximă, te poți simți mai ferit de priviri decât la tine în propriul spațiu ",
  },
  {
    title: "COMODITATE",
    paragraph:
      "Este foarte comod, fără multe pregătiri și fără deplasări, totul devine mai ușor",
  },
  {
    title: "MOTIVAȚIE",
    paragraph:
      "te vei simți mai motivat să parcurgi tratamentul, eliminarea atâtor bariere, ușurința cu care poți accesa ședințele te vor motiva să continui atât cât ai nevoie",
  },
];
const CumFunctioneaza = () => {
  const { userType } = useContext(UserContext);
  useEffect(() => {
    console.log(userType);
  }, []);
  return (
    <>
      <Seo title="Cum Functioneaza" description=" Cum Functioneaza" />
      <Header />
      <Hero
        title="Cum Funcționează"
        background="cum-functioneaza"
        image={cumfunctioneaza}
        imgback={true}
      />
      <ContentImage
        image={platforma}
        elipse={true}
        title={"Despre Platformă"}
        link="/register"
        text_link="Fa-ti cont gratuit"
        paragraph={
          <>
            <p>
              Clinica virtuală Solution Talk îți oferă prin platforma sa
              posibilitatea de a vorbi cu un psihoterapeut sau cu un psihiatru,
              prin internet, din confortul casei tale sau de oriunde te-ai afla.
              Te poți conecta ușor de pe orice dispozitiv conectat la internet:
              smartphone, tabletă, laptop, desktop. <br></br>
              <br></br>Ca să fii sigur că vei discuta cu un profesionist care te
              va ajuta să găsești soluții eficiente, toți psihoterapeuții și
              medicii psihiatri cu care lucrăm sunt atent selectați pentru
              experiența și abilitățile lor în domeniu, sunt atestați de către
              Colegiul Psihologilor și Colegiul Medicilor și sunt susținuți să
              se perfecționeze continuu.
            </p>
          </>
        }
      />
      <section className=" pt-10 sm:pt-32 ">
        <div className="flex flex-col items-center sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto px-5   ">
          <h2 className="text-center text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-6 sm:pb-12">
            Cum funcționează?
          </h2>
          <div className=" w-full flex gap-6 sm:gap-6 pb-10 sm:pb-20 flex-col lg:flex-row">
            {cum_functioneaza.map((item, index) => (
              <div
                className="flex basis-1/4 bg-white  rounded-3xl flex-col shadow-lg 2xl:items-start  py-7 xl:py-9 px-8 xl:px-11 gap-3"
                key={index}
              >
                <div className="flex gap-2 xl:gap-5 items-center">
                  <span className=" text-4xl 2xl:text-5xl font-ralewayBold text-primary flex">
                    {index + 1}.
                  </span>
                  {/* <h4 className="font-ralewayBold text-dark-blue text-1xl 2xl:text-3xl uppercase">
                    {item.title}
                  </h4> */}
                </div>
                <span className="font-ralewayRegular text-base xl:text-base text-dark-blue">
                  {item.paragraph}
                </span>
              </div>
            ))}
          </div>
          <Link
            to="/register"
            className="mb-10 whitespace-nowrap inline-flex items-center uppercase font-ralewayBold justify-center px-14 py-3 border border-transparent rounded-full shadow-lg  text-white bg-primary hover:bg-primary-hover"
          >
            Fa-ti cont gratuit
          </Link>
        </div>
      </section>
      <BackWithText2
        title={"Beneficii"}
        services={services2}
        background="body-g"
        Beneficii
      />
      <div className=" py-10 sm:py-20">
        <ContentImage
          image={iMac}
          elipse={false}
          title={<span>DE CE SĂ FACI PSIHOTERAPIE. CUM TE VA AJUTA</span>}
          link="/register"
          text_link="Fa-ti cont gratuit"
          paragraph={
            <>
              <ul className="list-disc">
                <li>te vei cunoaște și te vei înțelege mai bine</li>
                <li>
                  te va ajuta să te echilibrezi emoțional, nu vei mai fi
                  copleșit de emoții
                </li>
                <li>
                  vei avea relații mai bune și te vei simți mai conectat cu
                  ceilalți
                </li>
                <li>
                  vei avea mai multă încredere în tine, te vei simți mai
                  pregătit pentru viitor
                </li>
                <li>îți dezvolți abilități pentru rezolvarea de probleme</li>
                <li>
                  vei simți că ai un sprijin în momentele dificile, cineva te
                  înțelege și îi pasă de tine
                </li>
                <li>
                  vei înțelege cum să îți crești copiii într-un mod armonios
                </li>
                <li>te vei simți mai bine și fizic, nu doar emoțional</li>
                <li>o să te bucuri mai mult de viață</li>
              </ul>
            </>
          }
        />
      </div>
      <Footer />
    </>
  );
};
export default CumFunctioneaza;
