import React from "react";
import heroimage from "../../assets/imgdesprenoi/image_despre_noi.png";
import path1 from "../../assets/imgdesprenoi/Path 290.png";
import path2 from "../../assets/imgdesprenoi/Path 237.png";
import Header from "../Header/Header";
const Hero = ({ title, background, image, imgback }) => {
  return (
    <>
      {console.log(background)}
      <section
        className={`px-10 sm:px-20 xl:px-52 sm:py-5 sm:py-14 xl:py-20 xl:h-600px 2xl:h-900px  bg-no-repeat bg-cover ${
          background === "desprenoi"
            ? "bg-desprenoi"
            : background === "cum-functioneaza"
            ? "bg-cum-functioneaza"
            : background === "back-blog"
            ? "bg-back-blog"
            : ""
        } bg-white sm:bg-none relative`}
      >
        <img
          src={path1}
          className="hidden sm:block absolute -left-36 w-1/2 xl:w-auto "
        />
        <div className=" sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px mx-auto px-4 row py-44 sm:py-32 bg-white/50  sm:py-0 flex  justify-end   ">
          <div className="col basis-full sm:basis-1/3 ">
            <h1 className=" text-4xl lg:text-5xl xl:text-7xl text-center sm:text-left relative  font-ralewayBold text-secondary pb-5 sm:pt-32 lg:pt-48">
              {title}
            </h1>
          </div>
          <div className="col  hidden  basis-2/3  relative   sm:flex items-center">
            <img
              className={`relative ${imgback ? "left-12" : ""} z-40 w-full`}
              src={image}
            />
            <img src={path2} className="absolute -right-32 -top-20 " />
          </div>
        </div>
      </section>
    </>
  );
};
export default Hero;
