/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../api";
import { createUser, getUser } from "../api/auth.api";
import { LoadingContext } from "./LoadingProvider";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useContext(LoadingContext);
  useEffect(() => {
    auth.auth().onAuthStateChanged((userData) => {
      let urlRoute = window.location.href.split("/");
      setUser(userData);
      if (userData) {
        auth
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(async function (idToken) {
            let accountType = "";
            if (urlRoute[urlRoute.length - 1] == "register") {
              setUserType("client");
              accountType = "client";
            } else if (urlRoute[urlRoute.length - 1] == "register-specialist") {
              accountType = "specialist";
              setUserType("specialist");
            }
            getUser(idToken, userData, accountType).then((response) => {
              setUser({ ...userData, user: response });
              console.log(response);
              if (response) setUserType(response.accountType);
              console.log(idToken);
              setLoading(false);
            });
          })
          .catch(function (error) {
            // Handle error
            console.log(error);
          });
      } else {
        setLoading(false);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, userType, setUserType }}>
      {props.children}
    </UserContext.Provider>
  );
};
