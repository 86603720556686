import React, { createRef, useEffect, useState } from "react";
import HeaderClient from "../../../components/Header/HeaderClient";
import avatar from "../../../assets/img/Elena_Ionescu.jpg";
import { Link } from "react-router-dom";
import { auth } from "../../../api";
import { getAppoiments } from "../../../api/getAppoiments.api";
import Moment from "moment";
import { toast } from "react-toastify";
import { cancelAppointmentByClient } from "../../../api/createAppoinment";
import { LoadingComponent } from "../../../components";
// import { cancelAppointment } from "../../../../../backend/controllers/appointment.controllers";
const sedinte = [
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
  {
    data: "01./02./2022",
    ora: "09:00 - 10:00",
    terapeut: "Ana Popescu",
    locatie: "Online",
    cost: "100",
  },
];

const IstoricSedinteClient = () => {
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  const [loadingPast, setLoadingPast] = useState(true);
  const [filterByName, setFilterByName] = useState();
  const [filterAsc, setFilterAsc] = useState();
  const [filterDesc, setFilterDesc] = useState();
  const [filterOnline, setFilterOnline] = useState();

  var popupRef = createRef();

  const cancelAppointment = () => {
    var now = Moment();
    var date = Moment(selectedAppointmentDate);
    var diff = date.diff(now);
    if (Moment.duration(diff).asHours() < 48) {
      toast.error(
        "Nu poti anula programarea cu mai putin de 48 de ore inainte!",
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      auth
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(async function (idToken) {
          cancelAppointmentByClient(selectedAppointment, idToken).then(
            (res) => {
              if (res.status == 200) {
                toast.success("Programarea a fost anulata cu succes!", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setShowPopup(false);

                var filtered = futureAppointments.filter((app) => {
                  return app._id != selectedAppointment;
                });
                setFutureAppointments(filtered);
              } else {
                toast.error("Eroare la anularea programarii!", {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          );
        });
    }
  };
  // const [apps, setApps] = useState([]);
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoiments(idToken).then((res) => {
          console.log(res);
          if (res.status == 200) {
            // setApps(res.appointments);
            let apps = [];
            let fapps = [];
            res.data.appointments.forEach((element) => {
              if (Moment(element.date) < Moment()) apps.push(element);
              else fapps.push(element);
            });
            setPastAppoiments(apps);
            setFutureAppointments(fapps);
            setLoadingPast(false);
            console.log(pastAppointments);
            console.log(futureAppointments);
            // setAppoiments(res);
          } else {
            setLoadingPast(false);
          }
        });
      });
  }, []);

  return (
    <>
      <HeaderClient />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="mb-3 block grid grid-cols-1 xl:grid-cols-7">
          <h4 className="col-span-1 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Filtreaza Istoric
          </h4>
          <h4 className="hidden 2xl:block col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Istoric Sedinte
          </h4>
          <h4 className="hidden 2xl:block col-span-2 2xl:col-span-3 font-openSans show-review text-dark-blue pb-4 text-lg flex items-center gap-4 font-bold">
            Sedinte Programate
          </h4>
        </div>
        <div className="2xl:grid  grid-cols-1 2xl:grid-cols-7 gap-0 2xl:gap-5 mb-10 text-sm">
          <div className=" 2xl:col-span-1 bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="left  font-bold flex flex-col justify-between  md:flex-row 2xl:flex-col gap-2 sm:gap-5 2xl:gap-0">
                <div>
                  <h3 className="text-dark-blue pb-2.5">Denumire Specialist</h3>
                  <input
                    onChange={(e) => setFilterByName(e.target.value)}
                    className="2xl:mb-4 max-w-full border rounded-md h-9 text-lg px-3"
                    type="text"
                  />
                </div>
                {/* <div>
                  <h3 className="text-dark-blue pb-2.5">Data</h3>
                  <div className="flex items-center gap-1 2xl:pb-6">
                    <input
                      className="w-full border rounded-md h-9 text-lg px-3"
                      type="text"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="1"
                      viewBox="0 0 9 1"
                    >
                      <line
                        id="Line_227"
                        data-name="Line 227"
                        x2="9"
                        transform="translate(0 0.5)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                      />
                    </svg>
                    <input
                      className="w-full border rounded-md h-9 text-lg px-3"
                      type="text"
                    />
                  </div>
                </div> */}
                <div
                  className="min-w-max"
                  // className={`bg-white rounded-xl ${
                  //   buttonShow === true ? "hidden" : ""
                  // } sm:flex flex-col p-5 shadow-lg`}
                >
                  <h3 className="text-dark-blue pb-2.5 ">Organizeaza:</h3>
                  <div className="flex flex-col gap-2.5 text-dark-blue pb-2 sm:pb-8">
                    <div className="flex items-center gap-2.5">
                      <input
                        name="recente"
                        type="checkbox"
                        onClick={(e) => {
                          setFilterDesc(e.target.checked);
                        }}
                        className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                      />
                      <span>Cele mai recente</span>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <input
                        name="vechi"
                        type="checkbox"
                        onClick={(e) => setFilterAsc(e.target.checked)}
                        className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                      />
                      <span>Cele mai vechi</span>
                    </div>
                  </div>
                </div>
                {/* <div className="min-w-max">
                  <h3 className="text-dark-blue pb-2.5 ">Tipologie</h3>
                  <div className="flex flex-col gap-2.5 text-dark-blue">
                    <div className="flex items-center gap-2.5">
                      <input
                        name="online"
                        type="checkbox"
                        className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                      />
                      <span>Sedinte Online</span>
                    </div>
                    <div className="flex items-center gap-2.5">
                      <input
                        name="cabinet"
                        type="checkbox"
                        className=" h-4 w-4 text-indigo-600 border-gray-900 rounded"
                      />
                      <span>Sedinte la Cabinet</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <h4 className="block 2xl:hidden col-span-4 2xl:col-span-3 font-openSans show-review text-dark-blue pt-4 text-lg flex items-center gap-4 font-bold">
            Istoric Sedinte
          </h4>
          <div className=" pt-5 2xl:pt-0 h-auto col-span-4 2xl:col-span-3 flex flex-col overflow-hidden  items-start gap-4">
            {loadingPast ? (
              <LoadingComponent />
            ) : (
              <>
                <ul className="hidden sm:grid grid-cols-12 w-full bg-gray-inactive-bg text-dark-blue font-ralewayBold text-xs py-3.5 pl-3 sm:pl-9 pr-2">
                  <li className="col-span-1">NR.</li>
                  <li className="col-span-2">DATA</li>
                  <li className="col-span-2">INTERVAL ORAR</li>
                  <li className="col-span-2">TERAPEUT</li>
                  <li className="col-span-2">LOCATIE</li>
                  <li className="col-span-1">COST</li>
                  <li className="col-span-1">FACTURA</li>

                  <li className="col-span-1">VEZI</li>
                </ul>
                {!pastAppointments
                  ? ""
                  : pastAppointments
                      .filter((sp) => {
                        var name = true;
                        if (filterByName) {
                          console.log(sp.specialistLastName);
                          name = (
                            sp.specialistLastName +
                            " " +
                            sp.specialistFirstName
                          )
                            .toLowerCase()
                            .includes(filterByName.toLowerCase());
                          console.log(name);
                        }

                        return name;
                      })
                      .sort((a, b) => {
                        var fA;
                        var fB;
                        if (filterAsc) {
                          fA = 1;
                        } else {
                          fA = -1;
                        }
                        if (filterDesc) {
                          fB = 1;
                        } else {
                          fB = -1;
                        }

                        if (a.date > b.date) {
                          if (filterAsc) {
                            return 1;
                          }
                          if (filterDesc) {
                            return -1;
                          }
                          return 1;
                        } else if (a.date < b.date) {
                          if (filterAsc) {
                            return -1;
                          }
                          if (filterDesc) {
                            return 1;
                          }
                          return -1;
                        }
                        return 0;
                      })
                      .map((sedinta, index) => (
                        <>
                          <ul className=" hidden sm:grid grid-cols-12 w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <li className="col-span-1">{index + 1}.</li>
                            <li className="col-span-2">
                              {Moment(sedinta.date).format("YYYY-MM-DD")}
                            </li>
                            <li className="col-span-2">
                              {sedinta.startHour + " - " + sedinta.endHour}
                            </li>
                            <li className="col-span-2">
                              {sedinta.specialistLastName +
                                " " +
                                sedinta.specialistFirstName}
                            </li>
                            <li className="col-span-2">{sedinta.location}</li>
                            <li className="col-span-1">
                              {sedinta.type == "personal"
                                ? sedinta.price + " RON"
                                : 0.9 * parseInt(sedinta.price) + " RON"}
                            </li>
                            <li className="col-span-1">
                              <a href={sedinta.url}>URL</a>
                            </li>
                            <li className="col-span-1">
                              <Link
                                to={"/dashboard/client/sedinta/" + sedinta._id}
                              >
                                VEZI
                              </Link>
                            </li>
                          </ul>
                          <div className=" flex flex-col sm:hidden gap-1  w-full bg-white rounded-xl text-dark-blue font-ralewayRegular text-sm py-5 pl-3 sm:pl-9 pr-2 shadow ">
                            <div className=" grid grid-cols-2">
                              <span className="font-ralewayBold">NUMĂR:</span>
                              {index + 1}.
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">DATA:</span>
                              {Moment(sedinta.date).format("YYYY-MM-DD")}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">
                                INTERVAL ORAR:
                              </span>
                              {sedinta.startHour + " - " + sedinta.endHour}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">
                                TERAPEUT:
                              </span>
                              {sedinta.specialistLastName +
                                " " +
                                sedinta.specialistLastName}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">LOCATIE:</span>
                              {sedinta.location}
                            </div>
                            <div className="grid grid-cols-2">
                              <span className="font-ralewayBold">COST:</span>
                              {sedinta.type == "personal"
                                ? sedinta.price + " RON"
                                : 0.9 * parseInt(sedinta.price) + " RON"}
                            </div>
                          </div>
                        </>
                      ))}
              </>
            )}
          </div>
          <h4 className="block 2xl:hidden  w-full font-openSans show-review text-dark-blue pt-4 text-lg  items-center gap-4 font-bold">
            Sedinte Programate
          </h4>
          <div className="col-span-2 flex flex-col 2xl:col-span-3 overflow-hidden gap-4 pt-5 2xl:pt-0">
            {!futureAppointments
              ? ""
              : futureAppointments
                  .sort((a, b) => {
                    var fA;
                    var fB;
                    if (filterAsc) {
                      fA = 1;
                    } else {
                      fA = -1;
                    }
                    if (filterDesc) {
                      fB = 1;
                    } else {
                      fB = -1;
                    }

                    if (a.date > b.date) {
                      if (filterAsc) {
                        return 1;
                      }
                      if (filterDesc) {
                        return -1;
                      }
                      return 1;
                    } else if (a.date < b.date) {
                      if (filterAsc) {
                        return -1;
                      }
                      if (filterDesc) {
                        return 1;
                      }
                      return -1;
                    }
                    return 0;
                  })
                  .map((sedinta, index) => (
                    <>
                      <div className="bg-white shadow rounded-lg pb-6 pt-4 px-6 ">
                        <div className="flex flex-col sm:flex-row gap-2 items-start">
                          <div className="flex-shrink-0 inline-block w-16 h-16 justify-centers items-center rounded-full">
                            <img
                              src={sedinta.specialistAvatarUrl}
                              className="border-2 rounded-full p-0.5 border-primary object-cover object-center h-full w-full"
                            />
                          </div>
                          <div className="flex flex-col w-full">
                            <div>
                              <p className="text-primary font-bold">
                                {sedinta.specializationType}
                              </p>
                              <h6 className="font-bold text-secondary">
                                {sedinta.specialistLastName +
                                  " " +
                                  sedinta.specialistFirstName}
                              </h6>
                            </div>
                            <div className="sm:grid grid-cols-4 text-xs flex flex-col gap-2 sm:gap-0">
                              <div className="col-span-1 flex flex-col gap-1">
                                <p className=" text-gray-primary text-xs">
                                  {sedinta.lastStudies}
                                </p>
                                <h6 className="font-bold text-secondary flex gap-1 items-center text-xs">
                                  <svg
                                    id="location-pin-svgrepo-com_1_"
                                    data-name="location-pin-svgrepo-com"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8.912"
                                    height="14.167"
                                    viewBox="0 0 8.912 14.167"
                                  >
                                    <path
                                      id="Path_286"
                                      data-name="Path 286"
                                      d="M83.414,0a4.58,4.58,0,0,0-4.456,4.686c0,1.041.632,2.737,1.932,5.187.919,1.733,1.824,3.177,1.862,3.238l.662,1.056.662-1.056c.038-.061.942-1.505,1.862-3.238,1.3-2.45,1.932-4.146,1.932-5.187A4.58,4.58,0,0,0,83.414,0Zm0,7.083A2.372,2.372,0,0,1,81.1,4.654a2.313,2.313,0,1,1,4.619,0A2.372,2.372,0,0,1,83.414,7.083Z"
                                      transform="translate(-78.958)"
                                      fill="#f89a76"
                                    />
                                  </svg>
                                  {sedinta.specialistCity}
                                </h6>
                              </div>
                              <div className="col-span-2 flex flex-col gap-1 text-secondary">
                                <h4 className="font-ralewayBold">
                                  DATA{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {Moment(sedinta.date).format("YYYY-MM-DD")}
                                  </span>
                                </h4>
                                <h4 className="font-ralewayBold">
                                  INTERVAL ORAR{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.startHour +
                                      " - " +
                                      sedinta.endHour}
                                  </span>
                                </h4>
                              </div>
                              <div className="col-span-1 flex flex-col gap-1 text-secondary">
                                <h4 className="font-ralewayBold">
                                  LOCATIE{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.location}
                                  </span>
                                </h4>
                                <h4 className="font-ralewayBold">
                                  COST{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.price + " RON"}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5 flex-col sm:flex-row justify-center pt-4 sm:pt-7">
                          <Link to="#">
                            <button
                              onClick={(e) => {
                                setShowPopup(true);
                                setSelectedAppointment(sedinta._id);
                                setSelectedAppointmentDate(sedinta.date);
                              }}
                              className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary hover:bg-primary hover:text-white duration-300 text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                            >
                              Anuleaza Programarea
                            </button>
                          </Link>
                          {/* <Link to="#">
                          <button className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary text-white bg-primary hover:bg-white hover:text-primary duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                            Modifica Programarea
                          </button>
                        </Link> */}
                        </div>
                      </div>
                    </>
                  ))}
            {/* <div className="bg-white shadow rounded-lg pb-6 pt-4 px-6 ">
              <div className="flex flex-col sm:flex-row gap-2 items-start">
                <img
                  src={avatar}
                  className="border-2 rounded-full p-0.5 border-primary w-16"
                />
                <div className="flex flex-col w-full">
                  <div>
                    <p className="text-primary font-bold">Psihiatru</p>
                    <h6 className="font-bold text-secondary">Elena Popescu</h6>
                  </div>
                  <div className="sm:grid grid-cols-4 text-xs flex flex-col gap-2 sm:gap-0">
                    <div className="col-span-1 flex flex-col gap-1">
                      <p className=" text-gray-primary text-xs">
                        Doctor Psihoterapeut
                      </p>
                      <h6 className="font-bold text-secondary flex gap-1 items-center text-xs">
                        <svg
                          id="location-pin-svgrepo-com_1_"
                          data-name="location-pin-svgrepo-com"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.912"
                          height="14.167"
                          viewBox="0 0 8.912 14.167"
                        >
                          <path
                            id="Path_286"
                            data-name="Path 286"
                            d="M83.414,0a4.58,4.58,0,0,0-4.456,4.686c0,1.041.632,2.737,1.932,5.187.919,1.733,1.824,3.177,1.862,3.238l.662,1.056.662-1.056c.038-.061.942-1.505,1.862-3.238,1.3-2.45,1.932-4.146,1.932-5.187A4.58,4.58,0,0,0,83.414,0Zm0,7.083A2.372,2.372,0,0,1,81.1,4.654a2.313,2.313,0,1,1,4.619,0A2.372,2.372,0,0,1,83.414,7.083Z"
                            transform="translate(-78.958)"
                            fill="#f89a76"
                          />
                        </svg>
                        Bucuresti
                      </h6>
                    </div>
                    <div className="col-span-2 flex flex-col gap-1 text-secondary">
                      <h4 className="font-ralewayBold">
                        DATA{" "}
                        <span className="pl-2 font-ralewayRegular">
                          01./02./2022
                        </span>
                      </h4>
                      <h4 className="font-ralewayBold">
                        INTERVAL ORAR{" "}
                        <span className="pl-2 font-ralewayRegular">
                          09:00 - 10:00
                        </span>
                      </h4>
                    </div>
                    <div className="col-span-1 flex flex-col gap-1 text-secondary">
                      <h4 className="font-ralewayBold">
                        LOCATIE{" "}
                        <span className="pl-2 font-ralewayRegular">Online</span>
                      </h4>
                      <h4 className="font-ralewayBold">
                        COST{" "}
                        <span className="pl-2 font-ralewayRegular">
                          100 Ron
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 flex-col sm:flex-row justify-center pt-4 sm:pt-7">
                <Link to="#">
                  <button className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary hover:bg-primary hover:text-white duration-300 text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                    Anuleaza Programarea
                  </button>
                </Link>
                <Link to="#">
                  <button className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary text-white bg-primary hover:bg-white hover:text-primary duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                    Modifica Programarea
                  </button>
                </Link>
              </div>
            </div> */}
            {/* <div className="bg-white shadow rounded-lg pb-6 pt-4 px-6 ">
              <div className="flex flex-col sm:flex-row gap-2 items-start">
                <img
                  src={avatar}
                  className="border-2 rounded-full p-0.5 border-primary w-16"
                />
                <div className="flex flex-col w-full">
                  <div>
                    <p className="text-primary font-bold">Psihiatru</p>
                    <h6 className="font-bold text-secondary">Elena Popescu</h6>
                  </div>
                  <div className="sm:grid grid-cols-4 text-xs flex flex-col gap-2 sm:gap-0">
                    <div className="col-span-1 flex flex-col gap-1">
                      <p className=" text-gray-primary text-xs">
                        Doctor Psihoterapeut
                      </p>
                      <h6 className="font-bold text-secondary flex gap-1 items-center text-xs">
                        <svg
                          id="location-pin-svgrepo-com_1_"
                          data-name="location-pin-svgrepo-com"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8.912"
                          height="14.167"
                          viewBox="0 0 8.912 14.167"
                        >
                          <path
                            id="Path_286"
                            data-name="Path 286"
                            d="M83.414,0a4.58,4.58,0,0,0-4.456,4.686c0,1.041.632,2.737,1.932,5.187.919,1.733,1.824,3.177,1.862,3.238l.662,1.056.662-1.056c.038-.061.942-1.505,1.862-3.238,1.3-2.45,1.932-4.146,1.932-5.187A4.58,4.58,0,0,0,83.414,0Zm0,7.083A2.372,2.372,0,0,1,81.1,4.654a2.313,2.313,0,1,1,4.619,0A2.372,2.372,0,0,1,83.414,7.083Z"
                            transform="translate(-78.958)"
                            fill="#f89a76"
                          />
                        </svg>
                        Bucuresti
                      </h6>
                    </div>
                    <div className="col-span-2 flex flex-col gap-1 text-secondary">
                      <h4 className="font-ralewayBold">
                        DATA{" "}
                        <span className="pl-2 font-ralewayRegular">
                          01./02./2022
                        </span>
                      </h4>
                      <h4 className="font-ralewayBold">
                        INTERVAL ORAR{" "}
                        <span className="pl-2 font-ralewayRegular">
                          09:00 - 10:00
                        </span>
                      </h4>
                    </div>
                    <div className="col-span-1 flex flex-col gap-1 text-secondary">
                      <h4 className="font-ralewayBold">
                        LOCATIE{" "}
                        <span className="pl-2 font-ralewayRegular">Online</span>
                      </h4>
                      <h4 className="font-ralewayBold">
                        COST{" "}
                        <span className="pl-2 font-ralewayRegular">
                          100 Ron
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 flex-col sm:flex-row justify-center pt-4 sm:pt-7">
                <Link to="#">
                  <button className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary hover:bg-primary hover:text-white duration-300 text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                    Anuleaza Programarea
                  </button>
                </Link>
                <Link to="#">
                  <button className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary text-white bg-primary hover:bg-white hover:text-primary duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold">
                    Modifica Programarea
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  // setSelectedLocation("");
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                onClick={cancelAppointment}
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IstoricSedinteClient;
