import React, { useContext, useEffect, useState } from "react";
import { Footer, Header, Seo } from "../../components";
import FormContact from "../../components/FormContact/FormContact";
import { UserContext } from "../../providers/UserProvider";

const questions = [
  {
    question:
      "Este psihoterapia online la fel de eficientă precum cea față în față?",
    answer:
      "Da, numeroase studii arată că psihoterapia online este la fel de eficientă ca varianta față în față.",
  },
  {
    question: "Cum sunt verificați psihoterapeuții și medicii psihiatri?",
    answer:
      "Sunt verificați în registrele Colegiului Psihologilor și cel al Medicilor, sunt verificate diplomele și acreditările studiilor pe care le afișează pe site-ul Solution Talk și susținem interviuri cu ei ca să ne asigurăm că au experiență și abilitățile necesare ca să poată oferi servicii eficiente.",
  },
  {
    question: "Cum aleg un psihoterapeut potrivit pentru mine?",
    answer:
      "Te sfătuim să consulți profilurile psihoterapeuților, să vezi ce studii și ce experiență au, să vizionezi prezentarea video a psihoterapeutului, să citești articolele pe care le-a postat pe site-ul Solution Talk și, nu în ultimul rând, să vezi ce probleme psihologice abordează terapeutul respectiv, anxietate, depresie, tulburări de personalitate etc. Când găsești unul cu care simți că rezonezi și în descrierea pe care a făcut-o arată că lucrează și cu probleme ca a ta, îl poți alege.",
  },
  {
    question: "Cât durează o ședință?",
    answer:
      "O ședință de psihoterapie individuală durează 50 de minute, ședința de cuplu sau familie durează 90 de minute. Ședințele de evaluare și consult psihiatric durează 50 și 25 de minute.",
  },
  {
    question: "Câte ședințe trebuie să fac?",
    answer:
      "Asta vei stabili împreună cu psihoterapeutul sau psihiatrul tău, în prima ședință. În funcție de complexitatea problemelor și de disponibilitatea ta de a lucra la ele, tratamentele pot dura de la câteva săptămâni și pot ajunge la câțiva ani, în unele cazuri.",
  },
  {
    question: "Cât costă o ședință?",
    answer:
      "Tarifele fiecărui specialist sunt afișate în pagina lui de prezentare de pe site-ul Solution Talk.",
  },
  {
    question: "Cum plătesc ședința?",
    answer:
      "Vei putea plăti în siguranță cu cardul prin procesatorul de plăți.",
  },
  {
    question: "Primesc banii înapoi dacă anulez o ședință?",
    answer:
      "Ca să primești banii înapoi, trebuie să anulezi ședința cu cel puțin 48 de ore înainte. Altfel, banii nu se vor returna.",
  },
];
const Contact = () => {
  const { userType } = useContext(UserContext);
  const [hiddens, setHiddens] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  useEffect(() => {
    console.log(userType);
  }, []);
  const onClickQuestion = (e, index) => {
    e.preventDefault();
    setHiddens((prevState) => ({
      ...prevState, // shallow copy all previous state
      [index]: !hiddens[index], // update specific key/value
    }));
  };
  return (
    <>
      <Seo title="Contact" description="Contact" />
      <Header background={true} />
      <section className=" py-20 sm:py-36 px-10 sm:px-20 xl:px-52">
        <div className="flex sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto gap-12 flex-col lg:flex-row">
          <div className="basis-1/2">
            <h3 className=" text-2xl sm:text-3xl xl:text-5xl font-ralewayBold text-secondary uppercase pb-4 xl:pb-6">
              Întrebări frecvente
            </h3>
            <p className=" text-lg sm:text-xl	font-ralewaySemibold text-secondary pb-6 sm:pb-12">
              Mai jos avem răspunsurile la cele mai frecvente întrebări pe care
              le-am primit.
            </p>

            <div className="flex flex-col border-t-2 border-secondary">
              {questions.map((q, index) => (
                <div className="caseta py-4 sm:py-6 border-b border-secondary">
                  <div className="flex items-center justify-between gap-4 pb-4 sm:pb-6">
                    <h5
                      className="font-ralewaySemibold text-secondary text-base sm:text-lg cursor-pointer"
                      data-index={index}
                      onClick={(e) => onClickQuestion(e, index)}
                    >
                      {q.question}
                    </h5>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.763"
                      height="11.503"
                      viewBox="0 0 18.763 11.503"
                      data-index={index}
                      className="cursor-pointer"
                      onClick={(e) => onClickQuestion(e, index)}
                    >
                      <path
                        id="Path_59"
                        data-name="Path 59"
                        d="M5763.425,874.5l8.321,8.321,8.321-8.321"
                        transform="translate(-5762.364 -873.439)"
                        fill="none"
                        stroke="#2e324d"
                        stroke-width="3"
                      />
                    </svg>
                  </div>

                  <p
                    data-index={index}
                    className={
                      (hiddens[index] ? "hidden " : "") +
                      "font-ralewayRegular text-sm sm:text-base text-secondary sm:pr-24"
                    }
                  >
                    {q.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="basis-1/2">
            <FormContact />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Contact;
