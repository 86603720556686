import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../api";
import { addCommentToAppointment } from "../../api/createAppoinment";
import { getAppoimentById } from "../../api/getAppoiments.api";

const NotiteClient = (props) => {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          console.log(res);
          setComment(res.appointment.comment);
        });
      });
    // setComment(props.comment);
    // const comm =
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\n";
    // setComment(comm);
  }, []);

  const onChange = (event) => {
    setComment(event.target.value);
  };

  const onSave = (e) => {
    e.preventDefault();
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        addCommentToAppointment(comment, id, idToken).then((res) =>
          console.log(res)
        );
      });
  };

  return (
    <>
      <form>
        <div>
          <div className="bg-white overflow-hidden shadow rounded-lg h-full">
            <div className="px-4 py-5 sm:p-10">
              <div className="mb-3">
                <p className="text-xl font-bold text-secondary font-openSans">
                  Notite sedinta
                </p>
              </div>
              <div className="bg-white overflow-hidden rounded-lg">
                <div className="">
                  <div className="mt-1">
                    {/* <textarea
                      rows={40}
                      onChange={onChange}
                      name="comment"
                      id="comment"
                      className="pl-3 pt-3 h-80 border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={comment}
                    /> */}

                    <p>{comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NotiteClient;
