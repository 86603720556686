import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../components";
import background from "../../assets/img/BlogPost.jpg";
import person1 from "../../assets/homepage/Persona1.png";
import { useParams } from "react-router-dom";

const BlogPost = (props) => {
  const { id } = useParams();
  const [post, setPost] = useState();
  useEffect(() => {
    fetch("https://cms.solutiontalk.ro/wp-json/wp/v2/posts/" + id)
      .then((response) => response.json())
      .then((posts2) => {
        console.log(posts2);
        setPost(posts2);
      });
  }, [props]);
  return (
    <>
      <Header />
      <div className="pt-5 bg-white"></div>
      <section
        // style={{
        //   backgroundImage: `url("${background}")`,
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
        className="h-300px lg:h-600px bg-center "
      >
        <div className="h-300px lg:h-600px w-full">
          {!post ? (
            ""
          ) : (
            <img
              className="w-full h-full object-top object-cover"
              src={post.featured_image_url}
            />
          )}
        </div>
      </section>
      <div className="bg-white px-10 sm:px-20 xl:px-52">
        <section className="max-w-1264px m-auto px-5 pt-14 flex flex-col pb-32 ">
          <h6 className=" text-sm sm:text-base xl:text-lg text-primary font-ralewayBold">
            Articol
          </h6>
          {!post ? (
            ""
          ) : (
            <div
              className="my-5 text-lg sm:text-xl xl:text-4xl font-ralewayBold text-dark-blue pb-4"
              dangerouslySetInnerHTML={{
                __html: "<h4>" + post.title.rendered + "</h4>",
              }}
            ></div>
          )}

          {/* <div className="flex w-full pb-4 sm:pb-8 gap-2.5 items-center">
            <img width={58} src={person1} />
            <div className="flex flex-col">
              <h4 className="font-ralewayBold  pb-1 text-lg text-secondary">
                Nume Specialist
              </h4>
              <h3 className="font-ralewayRegular text-gray-primary text-sm sm:text-xl">
                Martie 2022 | 7 min read
              </h3>
            </div>
          </div> */}
          <div className=" text-sm sm:text-base xl:text-lg ">
            {!post ? (
              ""
            ) : (
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: post.content.rendered }}
              />
            )}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default BlogPost;
