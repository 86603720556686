import React, { useContext, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { Footer, Header, Seo } from "../../components";
import { Link } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import blog from "../../assets/img/blog.png";
import latest_new from "../../assets/homepage/latest_new.jpg";
import person1 from "../../assets/homepage/Persona1.png";
import { useState } from "react";

const latestnew = [
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  ,
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
  ,
  {
    image: latest_new,
    type: "Article",
    title: "Lorem ipsum dolor sit amet",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae consequat ante. Aenean quis libero ac felis sollicitudin mattis. ",
    image_author: person1,
    author: "Nume Specialist",
    date: "Martie 2022",
  },
];

const Blog = () => {
  const { userType } = useContext(UserContext);
  const [posts, setPosts] = useState();
  const [nameFilter, setNameFilter] = useState();
  useEffect(() => {
    console.log(userType);
    fetch("https://cms.solutiontalk.ro/wp-json/wp/v2/posts")
      .then((response) => response.json())
      .then((posts2) => {
        console.log(posts2);
        setPosts(posts2);
      });
  }, []);
  return (
    <>
      <Seo title="Cum Functioneaza" description=" Cum Functioneaza" />
      <Header />
      <Hero
        title="Blog"
        image={blog}
        imgback={false}
        background={"back-blog"}
      />
      <div className="background-elipse pt-32 2xl:pt-0 hidden sm:block  -mt-32 xl:mt-0 2xl:-mt-64 "></div>
      <section className="  relative bg-body-g sm:pt-20 2xl:pt-0 px-10 sm:px-20 xl:px-52">
        <div className="flex flex-col xl:flex-row sm:max-w-540px md:max-w-720px lg:max-w-960px xl:max-w-1180px m-auto  pb-6 sm:pb-14 xl:pb-28">
          <div className="basis-1/6 flex flex-col gap-5 mb-10 mt-10">
            <div className="bg-white p-5 rounded-2xl">
              <h5 className="font-bold mb-3">Caută articol</h5>
              <input
                className="border pl-2"
                placeholder="Nume articol"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              ></input>
            </div>
            {/* <div className="bg-white p-5 rounded-2xl">
              <h5>Categories</h5>
              <h3>Lorem Ipsum Dolor Sit</h3>
              <h3>Lorem Ipsum Dolor Sit</h3>
              <h3>Lorem Ipsum Dolor Sit</h3>
              <h3>Lorem Ipsum Dolor Sit</h3>
            </div> */}
            <div className="bg-white p-5 rounded-2xl flex flex-col gap-5 ">
              <h5 className="font-bold">Cele mai noi postări</h5>
              {!posts
                ? ""
                : posts.slice(0, 5).map((item, index) => (
                    <div>
                      <div
                        className="text-lg mb-1"
                        dangerouslySetInnerHTML={{
                          __html: "<h4>" + item.title.rendered + "</h4>",
                        }}
                      ></div>
                      {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam vitae consequat ante. Aenean quis libero ac felis
                  sollicitudin mattis.{" "}
                </p> */}
                    </div>
                  ))}
            </div>
          </div>
          <div className=" flex flex-wrap mt-4 pb-10 sm:pb-20 flex-col lg:flex-row basis-5/6 gap-10 lg:gap-0">
            {!posts
              ? ""
              : posts
                  .filter((post) => {
                    var name = true;
                    if (nameFilter) {
                      name = post.title.rendered
                        .toLowerCase()
                        .includes(nameFilter.toLowerCase());
                      console.log(name);
                    }
                    return name;
                  })
                  .map((item, index) => (
                    <div
                      className="flex basis-1/2 flex-col lg:p-4 items-start"
                      key={index}
                    >
                      <a
                        href={"/blog-post/" + item.id}
                        className="bg-white  rounded-3xl shadow-lg h-full"
                      >
                        <div className="w-full h-[236px] rounded-t-3xl">
                          <img
                            className="w-full h-full object-center object-cover rounded-t-3xl"
                            src={item.featured_image_url}
                          />
                        </div>
                        <div className="flex flex-col p-4 sm:p-9">
                          {/* <h3 className="text-primary sm:text-base font-ralewayBold">
                          {item.type}
                        </h3> */}
                          <div
                            className="text-xl font-bold mb-3"
                            dangerouslySetInnerHTML={{
                              __html: "<h3>" + item.title.rendered + "</h3>",
                            }}
                          ></div>
                          <div
                            className="text-lg"
                            style={({ fontWeight: 300 }, { fontSize: 15 })}
                            dangerouslySetInnerHTML={{
                              __html: item.excerpt.rendered,
                            }}
                          />
                          <div className="flex w-full pb-4 sm:pb-8 gap-2.5 items-center">
                            {/* <img width={58} src={item.image_author} /> */}
                            <div className="flex flex-col">
                              {/* <h4 className="font-ralewayBold  pb-1 text-lg text-secondary">
                              {item.author}
                            </h4>
                            <h3 className="font-ralewayRegular text-gray-primary text-sm sm:text-xl">
                              {item.date} | 7 min read
                            </h3> */}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Blog;
