import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";
import { auth } from ".";

export const updateUser = async (token, client) => {
  //   const { email, uid, metadata } = user._delegate;
  // var token = "";

  try {
    // console.log(token);
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/updateuser`,
      {
        user: client,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    // const response = res.data;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const addAngajat = async (token, idAngajat, idCompanie) => {
  try {
    // console.log(token);
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/addangajat`,
      {
        idAngajat: idAngajat,
        idCompanie: idCompanie,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    // const response = res.data;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getAngajati = async (idToken, companyId) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getangajati/${companyId}`,
      {
        headers: {
          id_token: idToken,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const updateUserAvatar = async (token, avatar) => {
  //   const { email, uid, metadata } = user._delegate;
  // var token = "";

  // token = idToken;
  try {
    console.log(token);
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/updateuseravatar`,
      {
        avatar: avatar,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    // const response = res.data;
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const approveUser = async (token, uid, approved) => {
  //   const { email, uid, metadata } = user._delegate;
  // var token = "";

  // token = idToken;
  try {
    console.log(token);
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/users/approveuser`,
      {
        uid: uid,
        approved: approved,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    // const response = res.data;
    return res;
  } catch (err) {
    console.log(err);
  }
};

// export const getUser = async (token, user, accountType) => {
//   try {
//     const res = await axios.get(`${process.env.REACT_APP_DEV_HOST}/api/users/getuser`, {
//       headers: {
//         id_token: token,
//       },
//     });
//     const response = res.data.user;
//     console.log(response.accountType);
//     return response;
//   } catch (err) {
//     if (err.response.status === 404) {
//       createUser(token, user, accountType).then((response) => {
//         console.log(response.user);
//         return response.user;
//       });
//     }
//   }
// };
