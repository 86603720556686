import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../../api";
import {
  cancelAppointmentByClient,
  cancelAppointmentBySpecialist,
} from "../../../api/createAppoinment";
import { getAppoiments } from "../../../api/getAppoiments.api";
import { LoadingComponent } from "../../../components";
import HeaderSecond from "../../../components/Header/HeaderSecond";
import FiltruSedinte from "../../../components/IstoricSedinte/FiltruSedinte";
import TabelaIstoricSedinte from "../../../components/IstoricSedinte/TabelaIstoricSedinte.jsx";
const cols = [
  { nume: "Nr.", hidden: false },
  { nume: "Data", hidden: false },
  { nume: "Interval orar", hidden: true, hiddenWidth: "lg:" },
  { nume: "Nume", hidden: false },
  { nume: "Locatie", hidden: true, hiddenWidth: "lg:" },
  // { nume: "Companie", hidden: true, hiddenWidth: "lg:" },
  { nume: "Cost", hidden: true, hiddenWidth: "lg:" },
  // { nume: "Comision", hidden: true, hiddenWidth: "lg:" },
];
const sed = [
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
  {
    fields: {
      nr: 1,
      data: "01/01/2022",
      intervalOrar: "09-11",
      nume: "Gica",
      locatie: "online",
      companie: "-",
      cost: "100 RON",
      comision: "10 RON",
      link: "https://google.com",
    },
    hiddenFields: ["intervalOrar", "comision", "locatie", "companie", "cost"],
    hiddenWidth: "lg:",
  },
];

const IstoricSedinte = () => {
  const [loading, setLoading] = useState(false);
  const [appointments, setAppoiments] = useState([]);
  const [saveApp, setSaveApp] = useState([]);
  const [locationFilterOnline, setLocationFilterOnline] = useState(false);
  const [locationFilterOffline, setLocationFilterOffline] = useState(false);
  const [pastAppointments, setPastAppoiments] = useState([]);
  const [futureAppointments, setFutureAppointments] = useState([]);
  const [showPopup, setShowPopup] = useState();
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  var popupRef = createRef();

  const changeStartDate = (e) => {
    e.preventDefault();
    const aux = appointments.appointments;
    console.log(aux);
    var filtered = aux.filter((tr) => {
      var start = moment(tr.date).format("DD-mm-yy");
      var end = moment(tr.date).format("DD-mm-yy");
      var date = moment().format("DD-mm-yy");
      return date < end;
    });
    setAppoiments(filtered);
  };

  const cancelAppointment = () => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        cancelAppointmentBySpecialist(selectedAppointment, idToken).then(
          (res) => {
            if (res.status == 200) {
              toast.success("Programarea a fost anulata cu succes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setShowPopup(false);

              var filtered = futureAppointments.filter((app) => {
                return app._id != selectedAppointment;
              });
              setFutureAppointments(filtered);
            } else {
              toast.error("Eroare la anularea programarii!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        );
      });
  };
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoiments(idToken).then((res) => {
          console.log(res);
          if (res.status == 200) {
            setSaveApp(res.data);
            setAppoiments(res.data);
            console.log(res);
            // setApps(res.appointments);
            let apps = [];
            let fapps = [];
            res.data.appointments.forEach((element) => {
              if (moment(element.date) < moment()) apps.push(element);
              else fapps.push(element);
            });
            setPastAppoiments(apps);
            setFutureAppointments(fapps);
            // setLoadingPast(false);
            console.log(apps);
            console.log(pastAppointments);
            console.log(futureAppointments);
          } else {
            console.log("test");
          }
        });
      });
  }, []);
  return (
    <>
      <HeaderSecond />
      <div className="max-w-1753px px-5 m-auto mb-10">
        <div className="grid grid-cols-1 xl:grid-cols-7 gap-7">
          <div className="xl:col-span-1">
            <FiltruSedinte
              setLocationFilterOnline={setLocationFilterOnline}
              setLocationFilterOffline={setLocationFilterOffline}
              onChangeStartDate={changeStartDate}
            />
          </div>
          <div className="xl:col-span-3">
            <div className="mb-3">
              <p className="text-lg font-bold">Istoric Sedinte</p>
            </div>
            {/* <button
              onClick={(e) => {
                setLocationFilterOnline(true);
                console.log(locationFilterOnline);
              }}
            >
              TESTARe
            </button> */}
            <TabelaIstoricSedinte
              columns={cols}
              sed={pastAppointments}
              locationFilterOnline={locationFilterOnline}
              locationFilterOffline={locationFilterOffline}
            />
          </div>
          <div className="xl:col-span-3">
            <div className="mb-3">
              <p className="text-lg font-bold">Sedinte programate</p>
            </div>
            <div className="col-span-2 flex flex-col 2xl:col-span-3 overflow-hidden gap-4 pt-5 2xl:pt-0">
              {!futureAppointments
                ? ""
                : futureAppointments.map((sedinta, index) => (
                    <>
                      <div className="bg-white shadow rounded-lg pb-6 pt-4 px-6 ">
                        <div className="flex flex-col sm:flex-row gap-2 items-start">
                          <div className="flex-shrink-0 inline-block w-16 h-16 justify-centers items-center rounded-full">
                            <img
                              src={sedinta.specialistAvatarUrl}
                              className="border-2 rounded-full p-0.5 border-primary object-cover object-center h-full w-full"
                            />
                          </div>
                          <div className="flex flex-col w-full">
                            <div>
                              {/* <p className="text-primary font-bold">
                                {sedinta.specializationType}
                              </p> */}
                              <h6 className="font-bold text-secondary">
                                {sedinta.client && sedinta.client != ""
                                  ? ""
                                  : "Fara client"}
                                {sedinta.client && sedinta.client != ""
                                  ? sedinta.clientLastName
                                    ? sedinta.clientLastName + " "
                                    : ""
                                  : ""}
                                {sedinta.client && sedinta.client != ""
                                  ? sedinta.clientFirstName
                                    ? sedinta.clientFirstName
                                    : ""
                                  : ""}
                              </h6>
                            </div>
                            <div className="sm:grid grid-cols-4 text-xs flex flex-col gap-2 sm:gap-0">
                              <div className="col-span-1 flex flex-col gap-1">
                                {/* <p className=" text-gray-primary text-xs">
                                  {sedinta.lastStudies}
                                </p> */}
                                <h6 className="font-bold text-secondary flex gap-1 items-center text-xs">
                                  {/* <svg
                                    id="location-pin-svgrepo-com_1_"
                                    data-name="location-pin-svgrepo-com"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8.912"
                                    height="14.167"
                                    viewBox="0 0 8.912 14.167"
                                  >
                                    <path
                                      id="Path_286"
                                      data-name="Path 286"
                                      d="M83.414,0a4.58,4.58,0,0,0-4.456,4.686c0,1.041.632,2.737,1.932,5.187.919,1.733,1.824,3.177,1.862,3.238l.662,1.056.662-1.056c.038-.061.942-1.505,1.862-3.238,1.3-2.45,1.932-4.146,1.932-5.187A4.58,4.58,0,0,0,83.414,0Zm0,7.083A2.372,2.372,0,0,1,81.1,4.654a2.313,2.313,0,1,1,4.619,0A2.372,2.372,0,0,1,83.414,7.083Z"
                                      transform="translate(-78.958)"
                                      fill="#f89a76"
                                    />
                                  </svg> */}
                                  {"Tip: " + sedinta.type}
                                </h6>
                              </div>
                              <div className="col-span-2 flex flex-col gap-1 text-secondary">
                                <h4 className="font-ralewayBold">
                                  DATA{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {moment(sedinta.date).format("YYYY-MM-DD")}
                                  </span>
                                </h4>
                                <h4 className="font-ralewayBold">
                                  INTERVAL ORAR{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.startHour +
                                      " - " +
                                      sedinta.endHour}
                                  </span>
                                </h4>
                              </div>
                              <div className="col-span-1 flex flex-col gap-1 text-secondary">
                                <h4 className="font-ralewayBold">
                                  LOCATIE{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.location}
                                  </span>
                                </h4>
                                <h4 className="font-ralewayBold">
                                  COST{" "}
                                  <span className="pl-2 font-ralewayRegular">
                                    {sedinta.price + " RON"}
                                  </span>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-5 flex-col sm:flex-row justify-center pt-4 sm:pt-7">
                          <Link to="#">
                            <button
                              onClick={(e) => {
                                setShowPopup(true);
                                setSelectedAppointment(sedinta._id);
                                setSelectedAppointmentDate(sedinta.date);
                              }}
                              className="w-full flex justify-center py-3 px-4 border rounded-md shadow-sm text-xs border-primary hover:bg-primary hover:text-white duration-300 text-primary bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                            >
                              Anuleaza Programarea
                            </button>
                          </Link>
                        </div>
                      </div>
                    </>
                  ))}
            </div>
          </div>
        </div>
        <div
          className={
            (!showPopup ? "hidden " : "") +
            "w-80 h-80 fixed inset-0 m-auto z-50"
          }
          ref={popupRef}
        >
          <div className="bg-white overflow-hidden shadow rounded-lg h-full w-full">
            <div className="px-4 py-5 sm:px-6 sm:py-4 bg-dark-blue relative">
              <p className="text-white font-openSans font-bold">
                Anulare programare
              </p>
              <p
                onClick={(e) => {
                  // setSelectedLocation("");
                  setShowPopup(false);
                }}
                className="text-white cursor-pointer w-fit absolute top-2.5 right-2.5"
              >
                x
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <p className="font-openSans text-xl text-dark-blue font-bold">
                Doriti sa continuati anularea programarii?
              </p>

              <button
                className="w-3/5 flex mx-auto mt-5 justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                onClick={cancelAppointment}
                type="button"
              >
                Anuleaza programarea
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IstoricSedinte;
