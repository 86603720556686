import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";

export const createappointment = async (price, appointment, token) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/createappointment`,

      {
        appointment: appointment,
        price: price,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    console.log(response);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const addClientToAppointment = async (
  location,
  name,
  appId,
  token,
  type,
  companyId,
  session_id
) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/addclienttoappointment`,
      {
        _id: appId,
        name: name,
        location: location,
        type: type,
        companyId,
        session_id,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res;
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const cancelAppointmentByClient = async (appId, token) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/cancelappointment`,
      {
        _id: appId,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res;
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const cancelAppointmentBySpecialist = async (appId, token) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/cancelappointmentbyspecialist`,
      {
        _id: appId,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res;
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const addCommentToAppointment = async (comment, appId, token) => {
  console.log(comment);
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/addcommenttoappointment`,
      {
        _id: appId,
        comment: comment,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    return response;
  } catch (err) {
    console.log(err);
  }
};
export const addPrivateCommentToAppointment = async (
  privateComment,
  appId,
  token
) => {
  // console.log(comment);
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_DEV_HOST}/api/appointment/addprivatecommenttoappointment`,
      {
        _id: appId,
        privateComment: privateComment,
      },
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data;
    return response;
  } catch (err) {
    console.log(err);
  }
};
