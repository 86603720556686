import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../../api";
import {
  addCommentToAppointment,
  addPrivateCommentToAppointment,
} from "../../api/createAppoinment";
import { getAppoimentById } from "../../api/getAppoiments.api";

const NotitePrivate = (props) => {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  useEffect(() => {
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        getAppoimentById(idToken, id).then((res) => {
          console.log(res);
          setComment(res.appointment.privateComment);
        });
      });
    // setComment(props.comment);
    // const comm =
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua\n";
    // setComment(comm);
  }, []);

  const onChange = (event) => {
    setComment(event.target.value);
  };

  const onSave = (e) => {
    e.preventDefault();
    auth
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(async function (idToken) {
        addPrivateCommentToAppointment(comment, id, idToken).then((res) =>
          console.log(res)
        );
      });
  };

  return (
    <>
      <form>
        <div>
          <div className="bg-white overflow-hidden shadow rounded-lg h-full">
            <div className="px-4 py-5 sm:p-10">
              <div className="mb-3">
                <p className="text-xl font-bold text-secondary font-openSans">
                  Adauga notite private
                </p>
              </div>
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="">
                  <div className="mt-1">
                    <textarea
                      rows={40}
                      onChange={onChange}
                      name="comment"
                      id="comment"
                      className="pl-3 pt-3 h-80 border-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      value={comment}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:float-right float-left mt-5 mb-7">
                  <button
                    onClick={onSave}
                    className="w-32 flex justify-center py-4 px-2 border border-transparent rounded-md shadow-sm text-xs text-white bg-primary hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-bold"
                  >
                    Salveaza Notite
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default NotitePrivate;
