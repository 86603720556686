import axios from "axios";

import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { HOST, PORT } from "../utils/Constants";

export const createUser = async (token, user, accountType) => {
  const { email, uid, metadata } = user._delegate;
  var publicId =
    Date.now() + "" + Math.floor(Math.random() * (999 - 100 + 1) + 100);
  var approved;
  if (accountType == "specialist") {
    approved = false;
  } else {
    approved = true;
  }
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_DEV_HOST}/api/users/createuser`,
      {
        user: { email, uid, metadata, accountType, publicId, approved },
      }
    );
    const response = res.data;
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getUser = async (token, user, accountType) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DEV_HOST}/api/users/getuser`,
      {
        headers: {
          id_token: token,
        },
      }
    );
    const response = res.data.user;
    console.log(response.accountType);
    return response;
  } catch (err) {
    if (err.response.status === 404) {
      createUser(token, user, accountType).then((response) => {
        console.log(response.user);
        return response.user;
      });
    }
  }
};
